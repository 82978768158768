import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import firebase, { storage, db } from './Firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './FirebaseAuthContext';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const { logout } = useAuth();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();

  // Check if the current location matches the post page pattern
  const isPostPage = location.pathname.startsWith('/post/') || location.pathname.startsWith('/profile/') || location.pathname.startsWith('/notifications');

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      const isMobileDevice = window.innerWidth <= 768; // Adjust the threshold as needed
      setIsMobile(isMobileDevice);
    }

    // Initial check on component mount
    handleResize();

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    
  useEffect(() => {
    getLoggedInUser();
  }, []);

  function openSidebar() {
    setIsOpen(!isOpen);
  }

  const logoutUser = async () => {
    await logout();
    navigate('/home');
  }

  function getLoggedInUser() {
    firebase.auth().onAuthStateChanged(async function(user) {
      if (user) {

        // get unseen notifications count
        const notiRef = db.collection('notifications').where('is_new', '==', true).where('to_user_id', '==', user.uid);
        const notificationDocs = await notiRef.get()
        setNotificationCount(notificationDocs.size)

        // increase noti count with every live noti
        const socket = io("https://twatter-notifications.sandersita.repl.co");
        socket.on(`noti-${currentUser.uid}`, () => {
          setNotificationCount((prevCount) => prevCount + 1)
        })
      }
    });
  }

  return (
    !isPostPage || !isMobile ? (
      <div className="Header">
      <span className="absolute text-white text-4xl top-3 left-3 cursor-pointer z-50" onClick={openSidebar}>
        <i className="bi bi-filter-left px-2 bg-gray-900 rounded-md border-2"></i>
      </span>
      <nav className={`headerw sidebar fixed top-0 bottom-0 lg:left-0 p-2 w-300px overflow-y-auto text-center bg-gray-900 border-r-2 ${isOpen ? '' : 'hidden'}`}>
        <div className="text-gray-100 text-xl">
          <div className="p-2.5 flex items-center">
            <h1 className="font-bold text-gray-200 text-[15px] ml-3">Twatter</h1>
            <i className="bi bi-x cursor-pointer ml-28" onClick={openSidebar}></i>
          </div>
          <div className="my-2 bg-gray-600 h-[1px]"></div>
        </div>
        <Link to={currentUser ? '/' : '/home'}>
          <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
            <i className="bi bi-house-door-fill"></i>
            <span className="text-[15px] ml-4 text-gray-200 font-bold">Home</span>
          </div>
        </Link>
        {!currentUser &&
          <Link to={'/'}>
            <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
              <i className="bi bi-megaphone"></i>
              <span className="text-[15px] ml-4 text-gray-200 font-bold">Feed</span>
            </div>
          </Link>
        }
        
        {!currentUser && 
          <>
            <Link to='/register'>
              <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
                <span className="text-[15px] ml-4 text-gray-200 font-bold">Register</span>
              </div>
            </Link>
            <Link to='/login'>
              <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
                <span className="text-[15px] ml-4 text-gray-200 font-bold">Login</span>
              </div>
            </Link>
          </>
        }

        {currentUser && 
          <><Link to='/notifications'>
              <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
                {/* <i className="bi bi-bell-fill"></i> */}
                
                <div className="relative inline-flex items-center text-center text-white rounded-lg">
                  <i className="w-5 h-5 bi bi-bell-fill"></i>
                  {notificationCount > 0 &&
                    <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                      {notificationCount}
                    </div>
                  }
                </div>

                <span className="text-[15px] ml-4 text-gray-200 font-bold">Notifications</span>
              </div>
            </Link>
          </>
        }

        {currentUser &&
          <Link to={`/profile/${currentUser.alias}`}>
            <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
              <i className="bi bi-person-fill"></i>
              <span className="text-[15px] ml-4 text-gray-200 font-bold">Profile</span>
            </div>
          </Link>
        }

        {currentUser && 
          <Link to='/login' onClick={logoutUser}>
            <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
              <i className="bi bi-box-arrow-left"></i>
              <span className="text-[15px] ml-4 text-gray-200 font-bold">Logout</span>
            </div>
          </Link>
        }
        
        {currentUser &&
          <Link className='bottom-0 absolute left-0 w-full p-2' to={"/profile/" + currentUser.alias}>
            <div className="mt-auto p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
              <img className='w-10 h-10 rounded' src={currentUser.pfp_url}></img>
              <div className='flex flex-col'>
                <span className="text-[15px] ml-4 text-gray-200 font-bold text-left">{currentUser.username}</span>
                <span className="text-[15px] ml-4 text-gray-500 text-left">@{currentUser.alias}</span>
              </div>
            </div>
          </Link>
        }
    </nav>
</div>
    ) : (null)
);
}

export default Header;

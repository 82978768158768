import React, { useEffect, useState } from "react";
import { io } from 'socket.io-client';
import firebase, { storage, db } from './Firebase';
import GroupChat from "./GroupChat";
import { showPopup } from "../sweetalert";
import { useAuth } from "./FirebaseAuthContext";

const RecentChats = ({ redDot, setRedDot, chats, setActiveChat }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showGroupChatModal, setShowGroupChatModal] = useState(false);
  const { currentUser } = useAuth();

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const showGroupChatModalC = () => {
    if(!currentUser){
      showPopup('', 'chat_solo')
      return;
    }
    setShowGroupChatModal(true)
  }

  const updateGreenDots = (chat_id) => {
    const findUnreadChats = chats.find(obj => obj.id == chat_id && obj.isUnread == true);
    if(findUnreadChats){
      findUnreadChats.isUnread = false;
    }

    const totalUnread = chats.filter(obj => obj.isUnread == true)
    console.log("totaaal unread: ", totalUnread)
    if(totalUnread.length == 0){
      setRedDot(false)
    }else{
      setRedDot(true)
    }
  }

  useEffect(() => {
    // check if in any chats that the user is in, the Unread is true ? show red dot
    firebase.auth().onAuthStateChanged(async function(user) {
      if(user){
        const chatRef = db.collection('chats').where('users', 'array-contains', user.uid)
        const querySnapshot1 = await chatRef.get()
  
        querySnapshot1.forEach(async doc => {
          const unReadRef = doc.ref.collection('userUnreadStatus').doc(user.uid)
          const querySnapshot = await unReadRef.get()

          if (querySnapshot.exists) {
            const unReadFoundFromUser = querySnapshot.data()

            if(unReadFoundFromUser.isUnread){
              setRedDot(true)
              return;
            }
          }
          
        })
      }
      

    })
    
  }, [])

  return (
    <>
    <div className={`fixed px-5 bottom-0 md:right-4 w-full md:w-96 bg-white border border-gray-200 rounded-t-lg shadow-md z-10 ${isOpen ? 'h-80' : ''}`}>
      <div
        className="flex items-center p-3 border-b border-gray-200 cursor-pointer"
        onClick={toggleChat}
      >

        <div className="relative inline-flex items-center text-center text-white rounded-lg gap-2">
          <h2 className="text-lg font-medium text-gray-700 cursor-pointer">Messages</h2>
          {redDot && 
            <div className="inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-green-500 rounded-full dark:border-gray-900"></div>
          }
        </div>
          
        <div className="flex-grow"></div> 
          <div className="flex gap-2 flex-shrink-0">
          <div className="group relative">
            <i onClick={(event) => {
            event.stopPropagation(); // Prevent event propagation
            showGroupChatModalC();
          }} className="text-2xl bi bi-envelope-plus p-2 rounded-full hover:duration-300 hover:bg-black hover:bg-opacity-20"></i>
          </div>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-8 h-8 text-blue-500 ${isOpen ? 'transform rotate-180' : ''}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
            </svg>
        </div>

      </div>
      {isOpen && (
        <div className="p-3 overflow-y-auto max-h-60">
          {chats.map((chat) => (
            <div
              key={chat.id}
              className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                setActiveChat(chat)
                updateGreenDots(chat.id)
              }}
            >
              <div className="flex items-center space-x-2">
                <img
                  className="w-8 h-8 rounded object-cover"
                  src={chat.image !== undefined ? chat.image : chat.pfp}
                  alt={chat.name !== undefined ? chat.name : chat.groupchatname}
                />

                <div className="flex flex-col">
                  <div className="flex d-flex gap-1">
                    <div className={`font-medium ${chat.isUnread ? 'text-black' : 'text-gray-800'}`}>{chat.name !== undefined ? chat.name : chat.groupchatname}</div>
                    <div className={`font-medium ${chat.isUnread ? 'text-black' : 'text-gray-800'}`}>{chat.alias !== undefined && chat.alias !== '' ? ('@' + chat.alias) : ''}</div>
                  </div>
                  <div className={`font-medium ${chat.isUnread ? 'text-black' : 'text-gray-800'}`}>{chat.lastmessage}</div>
                </div>
                
              </div>
              
              <div className="flex flex-col items-center">
                {chat.isUnread &&
                  <div className="inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-green-500 rounded-full dark:border-gray-900"></div>
                }
                <div className="font-medium text-gray-500">{chat.lastmessageTimestamp !== undefined ? formatDate(chat.lastmessageTimestamp) : ''}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
    
    {showGroupChatModal &&
      <GroupChat setShowGroupChatModal={setShowGroupChatModal} setActiveChat={setActiveChat}/>
    }
    </>
  );
};

function formatDate(jsTimestamp) {
  const dateObj = new Date(jsTimestamp);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (isSameDate(dateObj, today)) {
    // Format time if it's today
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  } else if (isSameDate(dateObj, yesterday)) {
    // Return "yesterday" if it's yesterday
    return 'Yesterday';
  } else {
    // Format date as "dd-mm-yyyy"
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  }
}

function isSameDate(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}


export default RecentChats;

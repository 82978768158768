import {Link} from 'react-router-dom';

const LoginBar = () => {
    return (
        <div className="w-full bottom-0 fixed bg-blue-600 gap-5 flex flex-row p-4 z-30 justify-evenly">
            <div className="flex-col text-white hidden sm:block">
                <h1 className="text-2xl font-bold">Don’t miss what’s happening</h1>
                <p>On Twatter you are the first to know</p>
            </div>
            <div className="flex gap-4 right-8 font-bold">
                <Link to="/login" className='my-auto'>
                    <button className="py-3 px-5 border-white rounded-full bg-white text-black">Login</button>
                </Link>
                <Link to="/register" className='my-auto'>
                    <button className="py-3 px-5 border border-white rounded-full text-white">Register</button>
                </Link>
            </div>
        </div>
    )
}
export default LoginBar;
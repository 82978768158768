import React from 'react';
import { useState, useEffect } from "react";
import FeedPost from './FeedPost';
import Reply from './Reply';
import firebase, { storage, db } from './Firebase';

function ReplyPost({ post }) {
  const [replyData, setReplyData] = useState([]);

  useEffect(() => {
    getReplyFromPost()
  }, [])

  async function getReplyFromPost() {
    // for the reply
    let replyData = {}

    const lastReplyIdReply = post.replyChain[post.replyChain.length - 1];
    replyData.id = lastReplyIdReply;
    replyData.user_id = post.user_id;

    // const userDataReply = await db.collection("users").doc(reply.user_id).get();
    // reply.usernameReply = userDataReply.data().username;
    // reply.aliasReply = userDataReply.data().alias;
    // reply.pfp_urlReply = userDataReply.data().pfp_url;

    let data = db.collection("posts").doc(post.replyChain[0]);

    for (let i = 1; i < post.replyChain.length; i++) {
      data = data.collection("replies").doc(post.replyChain[i]);
    }

    const likesSnapshotReply = await data.collection("likes").get();
    replyData.likesReply = likesSnapshotReply.docs.map((doc) => doc.data().userId);

    const replySnapshotReply = await data.collection("replies").get();
    replyData.replycountReply = replySnapshotReply.size;

    const postSnapshotReply = await data.get();
    replyData.text = postSnapshotReply.data().text;
    replyData.imgUrls = postSnapshotReply.data().imgUrls;
    replyData.timestamp = post.timestamp;
    replyData.post_id = post.replyChain[post.replyChain.length - 2]
    replyData.replyChainList = post.replyChain.splice(1)

    setReplyData(replyData)
  }

  return (
    <div className="">
      {replyData && replyData.id && (
        <>
          <FeedPost key={post.id} post={post} />
          <div className="w-0.5 bg-gray-400 h-full mx-5"></div> {/* Vertical line */}
          <Reply
            key={replyData.id}
            reply={replyData}
            post_id={replyData.post_id}
            reply_id={replyData.id}
            replyChainList={replyData.replyChainList}
          />
        </>
      )}
    </div>
  );
  
  
  
}


export default ReplyPost;
import React, { useEffect, useState } from "react";
import firebase, { storage, db } from './Firebase';
import { useParams, useNavigate, Link, useLocation  } from 'react-router-dom';
import { io } from 'socket.io-client';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from "react-responsive-carousel"; 

const Reply = ({ reply, post_id, reply_id, replyChainList, setReplyChainList }) => {
    const [user, setUser] = useState([]);
    const [userid, setUserid] = useState('');
    const [repliesCount, setRepliesCount] = useState(0);
    const [likesCount, setLikesCount] = useState(0);
    const [liked, setLiked] = useState(false)
    const [likesRef, setLikesRef] = useState('')

    const navigate = useNavigate();
    const location = useLocation();
    const getUserData = async () => {
        let data = await firebase.firestore().collection("users").doc(reply.user_id).get();
        setUser(data.data())

        firebase.auth().onAuthStateChanged(async function(user) {
            if (user) {
                setUserid(user.uid)
            }
        })
    }

    useEffect(() => {
        reply.text = reply.text.replace(/@([a-zA-Z0-9_]+)/g, '<a href="/profile/$1"><span className="text-blue-500">@$1</span></a>');
        replyCount();
    }, []);
      
    useEffect(() => {
        hasUserLiked();
    }, [likesRef, userid]);

    const replyCount = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const replyChain = urlParams.get('replyChain');

        const segments = location.pathname.split('/').filter(Boolean); // Remove empty segments
        const replyChainArray = segments.slice(3);

        let curPost = db.collection('posts').doc(post_id);

        for (const item of replyChainArray) {
            await curPost.collection('replies').doc(item).get();
        }

        const curPostRef = db.collection('posts').doc(post_id);
        const replyRef = curPostRef.collection('replies').doc(reply_id);

        // Get the reply document data
        const replyDoc = await replyRef.get();
        const replyData = replyDoc.data();

        // Get the replies subcollection
        const repliesRef = replyRef.collection('replies');

        // Get the replies
        const repliesSnapshot = await repliesRef.get();
        const repliesData = repliesSnapshot.docs.map(doc => doc.data());
        const repliesCount = repliesData?.length ?? 0;
        setRepliesCount(repliesCount);

        const replychainList = segments.slice(3);
    
        let postRef = firebase.firestore().collection("posts").doc(post_id);
    
        if (replychainList.length > 0) {
            for (const replyId of replychainList) {
                postRef = postRef.collection("replies").doc(replyId);
            }
        }

        postRef = postRef.collection("replies").doc(reply_id);

        const likeRef1 = postRef.collection('likes')
        setLikesRef(likeRef1)
    };


    // Check if user had liked this post
    const hasUserLiked = async () => {
        const currentUser = userid;
      
        if (currentUser && likesRef) {
          try {
            const likesSnapshot = await likesRef.get();
            const likesCount = likesSnapshot.size;
            setLikesCount(likesCount);
      
            const querySnapshot = await likesRef.where('userId', '==', currentUser).get();
            setLiked(!querySnapshot.empty);
          } catch (error) {
            console.error(`Error checking if user has liked: ${error}`);
          }
        }
      };
      

    function getUserFromPost(post_id){
        const postRef = db.collection('posts').doc(post_id)
        return postRef.get()
    }

    const likeReply = async () => {

        if(!liked){

            // ADD NOTIFICATION TO OTHER USER
            const notiRef = db.collection('notifications')
            const user = await getUserFromPost(post_id)
            const userIdFromPost = user.data().user_id
            if(userIdFromPost !== userid){
                const newNoti = {
                    from_user_id: userid,
                    timestamp: firebase.firestore.Timestamp.now(),
                    to_user_id: userIdFromPost,
                    post_id: post_id,
                    reply_id: reply_id,
                    type: "like",
                    is_new: true
                }
                notiRef.add(newNoti)
    
                const socket = io("https://twatter-notifications.sandersita.repl.co");
                socket.emit(`noti`, newNoti)
            }

            // Add a new document to the "likes" subcollection with the current user ID and timestamp
            await likesRef.add({
                userId: userid,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            });
            setLiked(true)
        }else{
            const querySnapshot = await likesRef.where('userId', '==', userid).get();
            querySnapshot.forEach(async (doc) => {
                await doc.ref.delete();
            });
            setLiked(false)
        }

        hasUserLiked();
        replyCount();
    }

    useEffect(() => {
        // fetch updated content when post_id or reply_id changes
        getUserData();
    }, [post_id, reply_id]);

    //add reply_id to replychain if there you are 1 reply layer deep
    const handleReplyClick = () => {
        const segments = location.pathname.split('/').filter(Boolean); // Remove empty segments
        const replychainList = segments.slice(3);

        const updatedReplyChain = [...replychainList, reply_id].join("/");
        navigate(`/post/${post_id}/reply/${updatedReplyChain}`);
    };
    return (
        <div style={{ cursor: "pointer" }}>
            <div className={'w-full border-b border-t py-3 border-gray-600 ' + (reply.likesReply === undefined ? 'p-1' : 'p-5')}>
                <div className='flex gap-5 m-0'>
                <Link state={{ user_id: userid }} to={`/profile/${user.alias}`}>
                    <div className='h-12 w-12 rounded overflow-hidden ml-2'>
                        <img className='h-full w-full object-cover' src={user.pfp_url} alt={user.username} />
                    </div>
                </Link>

                    <div className='flex flex-col w-full text-white' style={{ cursor: "pointer" }}>
                        <p><Link className="font-bold" state={{ user_id: userid }} to={`/profile/${user.alias}`}>{user.username}</Link><span className="text-gray-600"> @{user.alias} · {getTimeSince(reply.timestamp)}</span></p>
                        <a onClick={handleReplyClick} dangerouslySetInnerHTML={{ __html: reply.text }}></a>
                        {reply.imgUrls && reply.imgUrls.length > 0 && (
                            <div className='mt-2'>
                                <div className='w-full flex gap-5'>
                                {reply.imgUrls[0].split('?')[0].endsWith(".mp4") ? (
                                    <video controls className='w-1/2'>
                                        <source src={reply.imgUrls[0]} type='video/mp4' />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <Carousel className='rounded' dynamicWidth={true} dynamicHeight={true} emulateTouch={true} swipeable={true} showThumbs={false} showStatus={false} showIndicators={reply && reply.imgUrls > 1}>
                                        {reply.imgUrls.map(url => {
                                            return (
                                                <div key={url} onClick={handleReplyClick}>
                                                    <img src={url} className='' />
                                                </div>
                                            );
                                        })}
                                    </Carousel>
                                )}
                                </div>
                            </div>
                        )}
                        
                        <div className='w-full flex gap-5 mt-2'>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                            </svg> */}

                            <p>{repliesCount}</p>
                            <svg onClick={handleReplyClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="gray" className="w-6 h-6 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                            </svg>

                            <p>{likesCount}</p>
                            <svg onClick={() => likeReply()} xmlns="http://www.w3.org/2000/svg" fill={liked ? "red" : "none"} viewBox="0 0 24 24" strokeWidth={1.5} 
                                stroke={liked ? "red" : "gray"} className="w-6 h-6 cursor-pointer shadow-2xl">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function getTimeSince(timestamp) {
    
    let inputDate = timestamp;
    let inputTimestamp = new Date(inputDate).getTime();
    let currentTimestamp = new Date().getTime();
    let difference = currentTimestamp - inputTimestamp;

    let seconds = Math.floor(difference / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    let months = Math.floor(days / 30);

    

    if (seconds < 60) {
        return seconds + "s";
    } else if (minutes < 60) {
        return minutes + "m";
    } else if (hours < 24) {
        return hours + "h";
    } else if(days < 30) {
        return days + "d";
    }else {
        return months + "mo";
    }

}
export default Reply;
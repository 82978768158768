import { useState, useEffect, useRef } from "react";
import firebase, { storage, db } from "./Firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FeedPost from "./FeedPost";
import EmojiPicker from "emoji-picker-react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from "react-responsive-carousel";  
import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from './FirebaseAuthContext';
import { showPopup } from "../sweetalert";

function Feed() {
  const [feedState, setfeedState] = useState("recent");
  const [recentFeedData, setRecentFeedData] = useState([]);
  const [followingFeedData, setFollowingFeedData] = useState([]);
  const [postMessage, setpostMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [lastPost, setLastPost] = useState(null); // Declare and initialize lastPost
  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [dropdown, setShowDropdown] = useState('');
  const { currentUser } = useAuth();


  const observerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(handleScroll.scrollTimeout);
      handleScroll.scrollTimeout = setTimeout(() => {
        const scrollY = window.scrollY;
        localStorage.setItem('scrollYPosition', scrollY.toString());
      }, 500);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    // Restore the scroll position only if it hasn't been restored yet
    const savedScrollY = localStorage.getItem('scrollYPosition');
    if (savedScrollY && window.scrollY === 0) {
      const scrollY = parseInt(savedScrollY, 10);
      window.scrollTo(0, scrollY);
      localStorage.removeItem('scrollYPosition'); // Remove the saved scroll position
    }
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  


  const getUsers = async () => {
    const usersRef = db.collection('users');
    const usersSnapshot = await usersRef.get();
    const users = usersSnapshot.docs.map(doc => doc.data());
    setUsers(users)
    setFilteredUsers(users)
}

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const handleEmojiClick = (emojiObject) => {
    setpostMessage(postMessage + emojiObject.emoji); // append the selected emoji to the current message
  };

  const handleFileSelect = (event) => {
    setFiles(event.target.files);
  };

  const openFileExplorer = (event) => {
    event.preventDefault();
    document.getElementById("file-input").click();
  };
  

  const getPosts = async (lastPostFromNewPosts) => {
    try {
      if (lastPostFromNewPosts) {
        setFetchMoreLoading(true); // Indicate that more data is being fetched
      } else {
        setInitialLoading(true); // Indicate initial data loading
      }
  
      let query = db.collection("posts").orderBy("timestamp", "desc").limit(24);
  
      if (lastPostFromNewPosts) {
        const parsedDate = new Date(lastPostFromNewPosts.timestamp);
        const formattedTimestamp = parsedDate.toISOString();
        query = query.startAfter(formattedTimestamp); // Use the timestamp of the last post for pagination
      }
  
      const snapshot = await query.get();
      const newPosts = [];
  
      await Promise.all(
        snapshot.docs.map(async (doc) => {
          const userRef = db.collection("users").doc(doc.data().user_id);
          const userDoc = await userRef.get();
  
          const postData = doc.data();
          postData.username = userDoc.data().username;
          postData.alias = userDoc.data().alias;
          postData.pfp_url = userDoc.data().pfp_url;
          postData.id = doc.id;
  
          const timestampString = doc.data().timestamp;
          const timestamp = new Date(timestampString);
          postData.timestamp = timestamp;
  
          const likesRef = db
            .collection("posts")
            .doc(postData.id)
            .collection("likes");
          const likesSnapshot = await likesRef.get();
          const likes = likesSnapshot.docs.map((doc) => doc.data().userId);
          postData.likes = likes;
  
          const replyRef = db
            .collection("posts")
            .doc(postData.id)
            .collection("replies");
          const replySnapshot = await replyRef.get();
          const replies = replySnapshot.docs.map((doc) => doc.data().userId);
          postData.replycount = replies.length;
  
          newPosts.push(postData);
        })
      );
  
      const sortedPosts = lastPostFromNewPosts
        ? [...recentFeedData, ...newPosts].sort((a, b) => b.timestamp - a.timestamp)
        : [...newPosts].sort((a, b) => b.timestamp - a.timestamp);
        
      setRecentFeedData(sortedPosts);
  
      // Check if the fetched snapshot contains any documents
      if (snapshot.docs.length === 0) {
        setHasMorePosts(false);
      } else {
        // Update the last post only if there are new posts fetched
        if (newPosts.length > 0) {
          setLastPost(newPosts[newPosts.length - 1]); // Update with the last post from newPosts
        }
      }
  
      if (lastPostFromNewPosts) {
        setFetchMoreLoading(false);
      } else {
        setInitialLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setInitialLoading(false); // Handle error during initial loading
      setFetchMoreLoading(false); // Handle error during fetch more
    }
  };
  
  const getFollowingPosts = async () => {
    firebase.auth().onAuthStateChanged(async function(user) {
      let allFoundPosts = [];
    
      const followingRef = db.collection('following').doc(user.uid);
      const followingSnapshot = await followingRef.get();
      const followingData = followingSnapshot.data();
    
      if (followingData.following.length > 0) {
        for (let user_id of followingData.following) {
          const userPosts = db.collection('posts').where('user_id', '==', user_id);
          const userPostsSnapshot = await userPosts.get();
    
          await Promise.all(userPostsSnapshot.docs.map(async (postDoc) => {
            const post = postDoc.data();
    
            // Get the user data for the post
            const userRef = db.collection('users').doc(post.user_id);
            const userDoc = await userRef.get();
            const userData = userDoc.data();
    
            // Get the likes for the post
            const likesRef = db.collection('posts').doc(postDoc.id).collection('likes');
            const likesSnapshot = await likesRef.get();
            const likes = likesSnapshot.docs.map(doc => doc.data().userId);
    
            // Get the replies for the post
            const repliesRef = db.collection('posts').doc(postDoc.id).collection('replies');
            const repliesSnapshot = await repliesRef.get();
            const replies = repliesSnapshot.docs.map(doc => doc.data().userId);
    
            // Add the required data to the post object
            const completePostData = {
              ...post,
              username: userData.username,
              alias: userData.alias,
              pfp_url: userData.pfp_url,
              id: postDoc.id,
              timestamp: new Date(post.timestamp),
              likes: likes,
              replycount: replies.length
            };
    
            
            allFoundPosts.push(completePostData);
          }));
        }
    
        const sortedFeedData = allFoundPosts.sort((a, b) => b.timestamp - a.timestamp);
        setFollowingFeedData(sortedFeedData);
      }
    });
  }

  useEffect(() => {
    let isMounted = true;
    getUsers()
    getPosts();
    if(currentUser){
      getFollowingPosts()
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && hasMorePosts) {
        const lastPostFromNewPosts = recentFeedData[recentFeedData.length - 1];
        setLastPost(lastPostFromNewPosts);
        getPosts(lastPostFromNewPosts);
      }
    };
    
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this value as needed
    };
  
    observerRef.current = new IntersectionObserver(handleIntersection, options);
    const target = document.getElementById("observer");
  
    if (target) {
      observerRef.current.observe(target);
    }
  
    return () => {
      if (target) {
        observerRef.current.unobserve(target);
      }
    };
  }, [lastPost]); // Include lastPost in the dependency array
  

  async function createPost(event) {
    event.preventDefault();
    if(!currentUser){
      showPopup('', 'post')
      return;
    }
    if(postMessage == "" && files.length == 0){
      return;
    }

    // Show loading notification
   const loadingNotificationId = toast.info("Posting...", { autoClose: false });

    setpostMessage('')
    setFiles([])

    firebase.auth().onAuthStateChanged(async function (user) {
      var user_id = user.uid;
      let date = new Date();
      let timestamp = date.toISOString();
      let imgUrls = [];

      // If there are images, upload them to Cloud Storage and get the URLs
      if (files.length > 0) {
        const uploadPromises = [];
        for (const image of files) {
          const storageRef = storage.ref(`images/${image.name}`);
          const uploadTask = storageRef.put(image);
          uploadPromises.push(
            new Promise((resolve, reject) => {
              uploadTask.on(
                "state_changed",
                null,
                (error) => {
                  console.error(error);
                  reject(error);
                },
                async () => {
                  const url = await uploadTask.snapshot.ref.getDownloadURL();
                  resolve(url);
                }
              );
            })
          );
        }
        imgUrls = await Promise.all(uploadPromises);
      }
      // Create the post object
      const post = {
        text: postMessage,
        timestamp,
        user_id,
        imgUrls,
      };
      // Add the post to the 'posts' collection
      const addedPost = await db.collection("posts").add(post);
      const post_id = addedPost.id

      // text checken op alle @s, checken of ze allemaal kloppen
      const regex = /@([a-zA-Z0-9_]+)/g;
      const matches = [];
      let match;

      while ((match = regex.exec(postMessage))) {
        matches.push(match[1]);
      }

      const validMatches = [];

      await Promise.all(
        matches.map(async (match) => {
          const query = db.collection('users').where('alias', '==', match);
          const querySnapshot = await query.get();

          if (querySnapshot.docs.length > 0) {
            validMatches.push({
              match,
              user_id: querySnapshot.docs[0].id
            });
          }
        })
      );

      // alle notifications naar db,  notificatie naar alle @s
      await Promise.all(
        validMatches.map(async (match) => {
          const newNoti = {
            from_user_id: user_id,
            timestamp: firebase.firestore.Timestamp.now(),
            to_user_id: match.user_id,
            post_id: post_id,
            type: "mention",
            is_new: true
          }

          await db.collection('notifications').add(newNoti)

          const socket = io("https://twatter-notifications.sandersita.repl.co");
          socket.emit(`noti`, newNoti)
        })
      )

      // Update loading notification to success
    toast.update(loadingNotificationId, {
      render: "Post created successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 3000, // Adjust the time as needed
    });

    getPosts()

    });
  }

  const getUserImg = async () => {
    if(currentUser){
      const user = firebase.auth().currentUser;
      const user_id = user.uid;
      const userRef = db.collection("users").doc(user_id);
      const userData = (await userRef.get()).data();
      if (userData) {
        setImgUrl(userData.pfp_url);
      }
    }
    
  };

  getUserImg();

  function onchangePostMessage(msg, cursorIndex) {
    const atIndex = msg.lastIndexOf('@', cursorIndex - 1);
  
    if (atIndex !== -1) {
      // Check if the "@" is immediately followed by valid characters
      const aliasStartIndex = atIndex + 1;
      const aliasEndIndex = cursorIndex;
      const alias = msg.substring(aliasStartIndex, aliasEndIndex);
  
      // Ensure that alias is not empty and only contains valid characters
      const isValidAlias = alias.length === 0 || /^[a-zA-Z0-9_]+$/.test(alias);
  
      if (isValidAlias) {
        // Show the dropdown with user suggestions
        setShowDropdown(true);
  
        // Update the filteredUsers based on the input after "@" symbol
        const queryAfterAt = alias.toLowerCase();
        const filteredUsers = users.filter(user =>
          user.alias && user.alias.toLowerCase().includes(queryAfterAt)
        );
  
        // Update the state with filtered users
        setFilteredUsers(filteredUsers);
      } else {
        // Hide the dropdown if "@" is not followed by valid characters
        setShowDropdown(false);
      }
    } else {
      // Hide the dropdown if "@" is not present
      setShowDropdown(false);
    }
  
    // Update the state with the new post message
    setpostMessage(msg);
  }
  

  const setInputCursor = (index) => {
    const inputElement = document.getElementById('postMessage'); // Replace with your input element's actual ID
    inputElement.focus(); // Ensure the input element has focus
    inputElement.setSelectionRange(index, index); // Set cursor position
  };
  

  const handleUserSelection = (selectedAlias) => {
    const atIndex = postMessage.lastIndexOf('@');
    const cursorIndex = atIndex + 1; // Cursor index after the "@"
    
    const newPostMessage =
      postMessage.substring(0, cursorIndex) + selectedAlias + ' ' + postMessage.substring(cursorIndex);
    
    // Update the state with the new post message and hide the dropdown
    setpostMessage(newPostMessage);
    setShowDropdown(false);
    
    // Move the cursor to after the inserted alias
    const newCursorIndex = cursorIndex + selectedAlias.length + 1; // +1 for the space
    setInputCursor(newCursorIndex); // You need a function to update the cursor position
  };
  
  

  return (
    <SkeletonTheme baseColor="#1e2a45" highlightColor="#2d4069">
      <ToastContainer />
      <div className="Feed border-l border-r border-gray-600">
        <div className="grid grid-cols-2 text-center border-b 2 border-gray-600 sticky top-0 z-40 text-white ">
          <button onClick={() => setfeedState('recent')} className="col-span-1 bg-gray-900 pt-5 pb-5">
            <span className={feedState == "recent" ? "border-b-2 border-blue-700 p-2" : ""}>Recent</span>
          </button>
          <button onClick={() => setfeedState('following')} className="col-span-1 bg-gray-900 pt-5 pb-5">
            <span className={feedState == "following" ? "border-b-2 border-blue-700 p-2" : ""}>Following</span>
          </button>
        </div>

        <div className="w-full pl-5 pr-5 border-b 2 border-gray-600 text-white">
          <form
            className="space-y-4 md:space-y-6"
            action="#"
            onSubmit={createPost}
          >
            <input
              type="file"
              id="file-input"
              multiple
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            {/* <label for="file-input" className="btn" onClick={openFileExplorer}>Select files</label> */}

            <div className="flex gap-2 m-0">
              {currentUser && 
                <>
                  {imgUrl != "" ? (
                    <img
                      className="h-12 w-12 rounded-md"
                      src={
                        imgUrl != ""
                          ? imgUrl
                          : <Skeleton width={'3rem'} height={'3rem'} />
                      }
                    ></img>
                  ) : (
                  <Skeleton width={'3rem'} height={'3rem'} />
                  )}
                </>
              }
              
              

              <div className="w-full flex flex-col gap-4">
                <div className="flex-col relative">
                  <input
                    type="text"
                    name="postMessage"
                    id="postMessage"
                    value={postMessage}
                    onChange={(e) => onchangePostMessage(e.target.value, e.target.selectionStart)}
                    onBlur={() => setTimeout(() => setShowDropdown(false), 100)}
                    className="h-12 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="What are you up to?"
                  ></input>

                  {dropdown &&
                    <ul className="absolute z-40 p-2 bg-gray-900 w-full border rounded">
                        {filteredUsers.map(user => (
                            <a className='w-full' onClick={() => {handleUserSelection(user.alias); setShowDropdown(false)}}>
                                <div className="flex p-2 items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                                <img className='w-10 h-10 rounded' src={user.pfp_url}></img>
                                <div className='flex flex-col'>
                                    <span className="text-[15px] ml-4 text-gray-200 font-bold text-left">{user.username}</span>
                                    <span className="text-[15px] ml-4 text-gray-500 text-left">@{user.alias}</span>
                                </div>
                                </div>
                            </a>
                        ))}
                    </ul>
                  }
                </div>
                

              {files.length > 0 &&
                <div className="selected-files w-full flex mt-2 flex-wrap">
                <Carousel className='rounded' dynamicHeight={true} emulateTouch={true} swipeable={true} showThumbs={false} showStatus={false} showIndicators={files.length > 1}>

                {Array.from(files).map((file) => {
                  const fileType = file.type.split('/')[0]; // Get the first part of the MIME type (e.g., 'image', 'video')

                  if (fileType === 'image') {
                    // If the file is an image, render an <img> tag
                    return (
                      <img
                        key={file.name}
                        src={URL.createObjectURL(file)}
                        alt="selected"
                        className="w-1/2"
                      />
                    );
                  } else if (fileType === 'video') {
                    // If the file is a video, render a <video> tag
                    return (
                      <video key={file.name} controls className="w-1/2">
                        <source src={URL.createObjectURL(file)} type={file.type} />
                        Your browser does not support the video tag.
                      </video>
                    );
                  }

                  // If the file type is unknown or unsupported, you can render an error message or handle it accordingly
                  return (
                    <div key={file.name}>Unsupported file type: {file.name}</div>
                  );
                })}
                </Carousel>

              </div>
              }
              
                <div className="grid grid-cols-2">
                  <div className="flex gap-4">
                    <svg
                      onClick={openFileExplorer}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="cursor-pointer my-auto w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      />
                    </svg>
                    <svg
                      onClick={toggleEmojiPicker}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="cursor-pointer my-auto w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                      />
                    </svg>
                  </div>
                  <button
                    type="submit"
                    className="justify-self-end w-24 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Post
                  </button>
                </div>
                {showEmojiPicker && (
                  <div
                    className="absolute left-0 right-0 z-10 mx-auto"
                    style={{ maxWidth: "400px" }}
                  >
                    <EmojiPicker onEmojiClick={handleEmojiClick} theme="dark" />
                  </div>
                )}
              </div>
            </div>
          </form>
          
        </div>

        
          {feedState === 'recent' ? (
            <>
              {recentFeedData.length > 0 ? (
                <>
                {!initialLoading &&
                  <>
                    {recentFeedData.map((post) => (
                      <FeedPost key={post.id} post={post} />
                    ))}
                  </>
                }

                {fetchMoreLoading && (
                  <div>Loading more posts...</div>
                )}
                
                </>
              ) : (
                <>
                  {Array.from({ length: 24 }).map((_, index) => (
                    <div key={index} className="w-full p-5 border-b 2 border-gray-600">
                      <div className="flex gap-5">
                      <Skeleton className="w-12 h-12 rounded-md" width={'3rem'} height={'3rem'} />
                        <div className="flex flex-col w-full">
                          <p>
                            <Skeleton width={'200px'} />
                          </p>
        
                          <p><Skeleton width={'150px'} /></p>

                          <div className="w-full flex gap-5 mt-2 text-gray-400">
                              <p>0</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                                />
                              </svg>

                            <p>0</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="gray"
                              className="w-6 h-6 cursor-pointer shadow-2xl"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              
              </>
          ) : (
            <>
            {followingFeedData.length > 0 ? (
                <>
                  {followingFeedData.map((post) => (
                    <FeedPost key={post.id} post={post} />
                  ))}
                </>
              ) : ( null
              )}
            </>
          )}
<div id="observer"></div>
      </div>
    </SkeletonTheme>
  );
}

export default Feed;

import React, { useState, useRef, useEffect } from "react";
import firebase, { storage, db } from "./Firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UserItem from "./UserItem";
import EmojiPicker from "emoji-picker-react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";


function GroupChat({ setShowGroupChatModal, setActiveChat }) {
    const [userList, setUserList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [showSecondScreen, setShowSecondScreen] = useState(false);
    const fileInputRef2 = useRef(null);
    const [pfp, setPfp] = useState(null);
    const [chatName, setChatName] = useState("");

    const handleClickPfp = () => {
      fileInputRef2.current.click();
    };
  
    const handleChangePfp = (event) => {
      const selectedFile = event.target.files[0];
      setPfp(URL.createObjectURL(selectedFile));
    };

    const close = () => {
      setShowGroupChatModal(false)
    }

    const removeSelectedUser = (user) => {
      setSelectedUsers(selectedUsers.filter(selectedUser => selectedUser !== user));
    }

    const addUser = (user) => {
      const findUser = selectedUsers.find(item => item.user_id == user.user_id)
      
      if(findUser){
        removeSelectedUser(user)
      }else{
        setSelectedUsers([...selectedUsers, user])
      }
    }

    const fetchBlob = async (url) => {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "default",
      });
      const clone = response.clone();
      const blob = await clone.blob();
      return blob;
    };

    const uploadImage = async (image) => {
      try {
        let image_url = "";
        const blob = await fetchBlob(image);
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`images/${new Date().getTime()}`);
        const uploadTask = await fileRef.put(blob);
  
        if (uploadTask.state === "success") {
          image_url = await uploadTask.ref.getDownloadURL();
          return image_url;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getUsers = async () => {
      const usersSnapshot = await db.collection('users').get();
      const usersList = [];

      const user = firebase.auth().currentUser;
      const user_id = user.uid;

      usersSnapshot.forEach((userDoc) => {
        const userData = userDoc.data();
        if(userData.user_id !== user_id){
          usersList.push(userData);
        }
      });

      setUserList(usersList);
    }

    // start a new chat
    const startChat = async () => {
      const user = firebase.auth().currentUser;
      const user_id = user.uid;

      const chatsRef = db.collection('chats');
      const query = chatsRef.where('users', 'array-contains', user_id);
      const querySnapshot = await query.get();
      const newUserList = [...selectedUsers.map(user => user.user_id), user_id];
      let exists = false;
      let chatId;
    
      for (const doc of querySnapshot.docs) {
        const chat = doc.data();
    
        if (chatAlreadyExists(chat.users, selectedUsers)) {
          exists = true;
          chatId = doc.id;
        }
      }
    
      let username = "";
      if (!exists) {
        const chatRef = db.collection('chats');
        let newChatObj = {
          users: newUserList
        }
        if(chatName !== ""){
          newChatObj.groupchatname = chatName;
          newChatObj.pfp = pfp !== null ? await uploadImage(pfp) : 'https://firebasestorage.googleapis.com/v0/b/sql-api-e6e5f.appspot.com/o/images%2Fchat_group.jpg?alt=media&token=352ab259-83bb-4701-a5b8-e463774de194';
        }
        const newChat = await chatRef.add(newChatObj);
        chatId = newChat.id;

        // add unReadStatus collection with all both users
        const chatDocRef = chatRef.doc(chatId)

        const batch = db.batch();
        for(let userId of newUserList){
          const unreadStatusRef = chatDocRef.collection('userUnreadStatus').doc(userId);
          if (userId !== user_id) {
            batch.set(unreadStatusRef, { isUnread: true });

            if(chatName == ""){
              const userRef = db.collection('users').doc(userId);
              const data = await userRef.get();
              username = data.data().username;
            }
          }else{
            batch.set(unreadStatusRef, { isUnread: false });
          }
        }
        // newUserList.forEach(async (userId) => {
          
        // });

        batch.commit()
      }
    
      const activeChat = {
        id: chatId,
        name: chatName == "" ? username : chatName,
        users: newUserList
      };
    
      setActiveChat(activeChat);
    };
    

    // compare 2 strings, one of the 2 user ids, and the other is from all chats the user is in
    const chatAlreadyExists = (array1, array2) => {
      if(array1.length !== array2.length){
        return false;
      }

      array1 = array1.sort();
      array2 = array2.sort();

      for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
          return false;
        }
      }

      return true;
    }

    useEffect(() => {
      getUsers()
    }, [])
  
  return (
    <div className="flex h-screen justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div style={{ height: '600px' }} className="flex flex-col shadow dark:bg-gray-700 rounded w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 mx-4 md:mx-auto">
          <div className="flex justify-between p-2 items-center gap-4">
            <button
              onClick={close}
              type="button"
              className="ml-2"
              data-modal-hide="authentication-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="flex-col mr-auto">
                <p className="text-white text-xl font-bold">Create a chat</p>
                {!showSecondScreen &&
                  <p className="text-gray-300">Add user(s)</p>
                }
                
            </div>
            <button onClick={() => {
              if(selectedUsers.length > 0){
                if(showSecondScreen){
                  if(chatName !== ""){
                    setShowGroupChatModal(false)
                    startChat();
                  }
                }
                if(selectedUsers.length > 1){
                  setShowSecondScreen(true);
                }else{
                  setShowGroupChatModal(false)
                  startChat(); 
                }
              }
            }} className={showSecondScreen ? ((chatName.trim() != "" ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-default')) + " text-white font-bold py-2 px-4 rounded-full" : (selectedUsers.length > 0 ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-default') + " text-white font-bold py-2 px-4 rounded-full"}>
                Continue
            </button>
          </div>

          {showSecondScreen == false ? (
            <>
              <input
              // onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="Search users"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            />

              <div className="flex flex-wrap gap-2 p-2">
                {selectedUsers.map((user) => (
                  <div className="flex gap-2 border border-gray-500 rounded-full pl-1 pr-1">
                    <img className="h-7 w-7 rounded-full my-auto" src={user.pfp_url}></img>
                    <p className="text-white font-bold my-auto">{user.username}</p>
                    <i onClick={() => removeSelectedUser(user)} style={{cursor:'pointer'}} className="bi bi-x text-2xl text-blue-500"></i>
                  </div>
                ))}
              </div>

              
              <div className="flex-col overflow-y-scroll">
                {userList.map(user => (
                  <UserItem user={user} addUser={addUser} isSelectedProp={selectedUsers.includes(user)} setIsSelected={setIsSelected} />
                ))}
            </div>
          </>
          ) : (
            <div className="p-5">
              <h1 className="font-bold text-white text-center">Chat image</h1>
              <div className="relative">
              <img className="w-1/2 max-h-64 object-cover mx-auto rounded" src={pfp == null ? "https://firebasestorage.googleapis.com/v0/b/sql-api-e6e5f.appspot.com/o/images%2Fchat_group.jpg?alt=media&token=352ab259-83bb-4701-a5b8-e463774de194" : pfp} alt="Profile" />
              <div
                onClick={handleClickPfp}
                className="cursor-pointer h-10 w-10 bg-black p-2 rounded-full bg-opacity-50 absolute top-0 left-0 bottom-0 right-0 m-auto flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <input
                  type="file"
                  ref={fileInputRef2}
                  style={{ display: "none" }}
                  onChange={handleChangePfp}
                />
              </div>
            </div>
              <h1 className="font-bold text-white">Chat name <span className="text-red-700">*</span></h1>
              <input
                onChange={(e) => setChatName(e.target.value.trim())}
                type="text"
                placeholder="Chat name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              />
            </div>
          )}

          
        </div>
    </div>
  );
}

export default GroupChat;

import React, { useEffect, useState } from "react";
import { useAuth } from "./FirebaseAuthContext";
import firebase, { storage, db } from './Firebase';
import { useParams, useNavigate, Link, useLocation  } from 'react-router-dom';
import Reply from "./Reply";
import { io } from 'socket.io-client';
import EmojiPicker from "emoji-picker-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from "react-responsive-carousel";  
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer, toast } from 'react-toastify';
import { showPopup } from "../sweetalert";

const Post = () => {
  const { currentUser } = useAuth();
  const [replies1, setReplies] = useState([]);
  const [post, setPost] = useState({});
  const [userData, setUserData] = useState({});
  const [reply, setReply] = useState("");
  const [replyChain, setReplyChain] = useState([]);

  const [liked, setLiked] = useState(false);
  const [postsLiked, setPostLikes] = useState(0);

  const [files, setFiles] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [likesCollection, setLikesCollection] = useState("");

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [dropdown, setShowDropdown] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [postText, setPostText] = useState("");

  const { post_id } = useParams();

  const navigate = useNavigate();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const replyChain1 = urlParams.get("replyChain");
  //const replyChainList = replyChain1 ? replyChain1.split("/") : [];

  const [replyChainList, setReplyChainList] = useState(replyChain1 ? [replyChain1.split("/")] : []);

  

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const handleEmojiClick = (emojiObject) => {
    setReply(reply + emojiObject.emoji); // append the selected emoji to the current message
  };

  const handleFileSelect = (event) => {
    setFiles(event.target.files);
  };

  const openFileExplorer = (event) => {
    event.preventDefault();
    document.getElementById("file-input").click();
  };

  useEffect(() => {
    getPost();
    setReplies([]);
    getReplies(post_id);
    getUsers();
  }, [location.pathname]);

  // Check if user had liked this post
  const hasUserLiked = async () => {
    firebase.auth().onAuthStateChanged(async function (user) {
      if(user){
        const user_id = user.uid
        const urlParams = new URLSearchParams(window.location.search);
        const replyChain1 = urlParams.get('replyChain');
        //const replychainList = replyChain1 ? replyChain1.split("/") : [];
  
        const segments = location.pathname.split('/').filter(Boolean); // Remove empty segments
        const replychainList = segments.slice(3);
    
        let likesCollection = db.collection('posts').doc(post_id).collection('likes');
        if (replychainList.length > 0) {
          let data = firebase.firestore().collection("posts").doc(post_id);
    
          for (const replyId of replychainList) {
            data = data.collection("replies").doc(replyId);
          }
    
          likesCollection = data.collection("likes");
        }

        // Get the updated number of likes from the database
        const likesSnapshot = await likesCollection.get();
        const likesCount = likesSnapshot.size;
        setPostLikes(likesCount);

        // Has liked?
        const querySnapshot = await likesCollection.where('userId', '==', user_id).get();
        setLiked(!querySnapshot.empty);
         
      }else{
          const postRef = db.collection('posts').doc(post_id).collection('likes');
    
          // Get the updated number of likes from the database
          const likesSnapshot = await postRef.get();
          const likesCount = likesSnapshot.size;
          setPostLikes(likesCount);
      }
      
    })
  };
  
  function getUserFromPost(post_id){
    const postRef = db.collection('posts').doc(post_id)
    return postRef.get()
  }

  // Update liked status to database
  const likePost = async (post_id) => {
    if(!currentUser){
      showPopup(userData.alias, 'like')
      return;
    }
    const currentUserId = currentUser.user_id
  
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const replyChain1 = urlParams.get('replyChain');
      //const replychainList = replyChain1 ? replyChain1.split("/") : [];
  
      const segments = location.pathname.split('/').filter(Boolean); // Remove empty segments
      const replychainList = segments.slice(3);
  
      let postRef = db.collection("posts").doc(post_id);
  
      if (replychainList.length > 0) {
        for (const replyId of replychainList) {
          postRef = postRef.collection("replies").doc(replyId);
        }
      }
  
      const likesCollection = postRef.collection("likes");
  
      // Get the updated number of likes from the database
      const likesSnapshot = await likesCollection.get();
      const likesCount = likesSnapshot.size;
  
      if (!liked) {
        // ADD NOTIFICATION TO OTHER USER
        const notiRef = db.collection('notifications');
        const user = await getUserFromPost(post_id);
        const userIdFromPost = user.data().user_id;
        if (currentUserId !== userIdFromPost) {
          const newNoti = {
            from_user_id: currentUserId,
            timestamp: firebase.firestore.Timestamp.now(),
            to_user_id: userIdFromPost,
            post_id: post_id,
            type: "like",
            is_new: true
          };
  
          notiRef.add(newNoti);
  
          const socket = io("https://twatter-notifications.sandersita.repl.co");
          socket.emit(`noti`, newNoti);
        }
  
        // Add a new document to the "likes" subcollection with the current user ID and timestamp
        await likesCollection.add({
          userId: currentUser.user_id,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        });
  
        // Set the liked state and update the state of the number of likes for the post
        setLiked(true);
        setPostLikes(likesCount + 1); // Increment the likes count


        // add like to likedPosts collection
        const likedPostsRef = db.collection('likedPosts')
        await likedPostsRef.add({
          post_id,
          from_user_id: currentUserId,
          timestamp: firebase.firestore.Timestamp.now(),
          userIdFromPost
        })
      } else {
        // Remove like from database
        const querySnapshot = await likesCollection.where('userId', '==', currentUserId).get();
        querySnapshot.forEach(async (doc) => {
          await doc.ref.delete();
        });
  
        // Set the liked state and update the state of the number of likes for the post
        setLiked(false);
        setPostLikes(likesCount - 1); // Decrement the likes count

        // delete like to likedPosts collection
        const likedPostsRef = db.collection('likedPosts');

        // Query the collection to retrieve the document with the specified post_id
        const querySnapshotlikedPosts = await likedPostsRef.where('post_id', '==', post_id).where('from_user_id', '==', currentUserId).get();

        if (!querySnapshotlikedPosts.empty) {
          // Get the document reference and delete it
          querySnapshotlikedPosts.forEach(async (doc) => {
            await doc.ref.delete();
          });
          console.log('Document deleted successfully.');
        }
      }
    } catch (error) {
      console.error(`Error liking post ${post_id}: ${error}`);
    }
  };
  
  let blueText = '';
  const getPost = async () => {
    const pathname = location.pathname;
    const segments = pathname.split('/').filter(Boolean); // Remove empty segments
    const post_id = segments[1]; // Assuming the post ID is at index 2
    const replychainList = segments.slice(3); // Starting from index 4, assuming "reply" is at index 3
  
    setReplyChain(replychainList);
  
    if (replychainList.length > 0) {
      let data = firebase.firestore().collection("posts").doc(post_id);
  
      for (const replyId of replychainList) {
        data = data.collection("replies").doc(replyId);
      }
  
      const likesCollection = data.collection("likes");
      setLikesCollection(likesCollection);
  
      const replyData = await data.get();
      const postRef = replyData.ref.parent.parent;
      const postData = await postRef.get();
  
      const post = postData.data();
      const reply = replyData.data();
  
      getUserData(reply.user_id);
      blueText = post.text.replace(/@([a-zA-Z0-9_]+)/g, '<a href="/profile/$1"><span className="text-blue-500">@$1</span></a>');
      setPostText(reply.text)
      setPost(reply);
      // getReplies()
      hasUserLiked();
    } else {
      const data = await db.collection("posts").doc(post_id).get();
      const res = data.data();
      getUserData(res.user_id);
      blueText = res.text.replace(/@([a-zA-Z0-9_]+)/g, '<a href="/profile/$1"><span className="text-blue-500">@$1</span></a>');
      setPostText(blueText)
      setPost(res);
      //getReplies()
      hasUserLiked();
    }
  };

  const getUserData = async (user_id) => {
    let data = await db.collection("users").doc(user_id).get();
    setUserData(data.data())
  }

  const getReplies = async (post_id) => {
  
    // Gebruik useLocation om het huser_idige pad van de URL te verkrijgen
    const pathSegments = location.pathname.split("/").filter(Boolean); // Verwijder lege segmenten
  
    if (pathSegments.length > 3) {
      // Er zijn reply-id's in de URL
      let data = db.collection("posts").doc(post_id);
  
      for (let i = 3; i < pathSegments.length; i++) {
        // Begin bij index 3 omdat "/dashboard/post/..." overgeslagen moet worden
        data = data.collection("replies").doc(pathSegments[i]);
      }
  
      const nestedQuerySnapshot = await data.collection("replies").get();
  
      const dataList = nestedQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setReplies(dataList);
    } else {
      // Geen reply-id's in de URL, haal de replies van de oorspronkelijke post op
      const dataList = [];
      const data = await db.collection("posts").doc(post_id).collection("replies").get();
  
      data.docs.forEach((doc) => {
        dataList.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      setReplies(dataList);
    }
  };

  const replyToDatabase = async (event) => {
    setReply('')
    setFiles([])
    event.preventDefault()
    if(!currentUser){
      showPopup(userData.alias, 'reply')
      return;
    }
    if (!post_id || !replyChain || (reply == "" && files.length == 0)) {
      // handle error or return
      return;
    }

    // Show loading notification
   const loadingNotificationId = toast.info("Replying...", { autoClose: false });

    const now = new Date();
    const timestamp = now.toISOString();
  
    let postRef = firebase.firestore().collection("posts").doc(post_id);

    const segments = location.pathname.split('/').filter(Boolean); // Remove empty segments
    const replychainList = segments.slice(3);
  
    for (const reply_id of replychainList){
      postRef = postRef.collection("replies").doc(reply_id);
    }

    postRef = postRef.collection("replies").doc();

    let imgUrls = [];

    // If there are images, upload them to Cloud Storage and get the URLs
    if (files.length > 0) {
      const uploadPromises = [];
      for (const image of files) {
          const storageRef = storage.ref(`images/${image.name}`);
          const uploadTask = storageRef.put(image);
          uploadPromises.push(
          new Promise((resolve, reject) => {
              uploadTask.on(
              "state_changed",
              null,
              (error) => {
                  console.error(error);
                  reject(error);
              },
              async () => {
                  const url = await uploadTask.snapshot.ref.getDownloadURL();
                  resolve(url);
              }
              );
          })
          );
      }
      imgUrls = await Promise.all(uploadPromises);
    }
  
    const newReply = {
      text: reply,
      timestamp: timestamp,
      user_id: currentUser.user_id,
      imgUrls
    }

    await postRef.set(newReply);

    const replyPostsRef = db.collection('replyPosts')
    await replyPostsRef.add({...newReply, user_id_from_parent: userData.user_id, replyChain: [post_id, ...replyChain, postRef.id]})

    // ADD NOTIFICATION TO OTHER USER
    if(currentUser.user_id !== userData.user_id){
      const notiRef = db.collection('notifications')
      let newNoti = {
        from_user_id: currentUser.user_id,
        timestamp: firebase.firestore.Timestamp.now(),
        to_user_id: userData.user_id,
        post_id: post_id,
        reply_text: reply,
        type: "reply",
        is_new: true
      }
  
      notiRef.add(newNoti)
  
      const socket = io("https://twatter-notifications.sandersita.repl.co");
      socket.emit(`noti`, newNoti)
    }

    // text checken op alle @s, checken of ze allemaal kloppen
    const regex = /@([a-zA-Z0-9_]+)/g;
    const matches = [];
    let match;

    while ((match = regex.exec(reply))) {
      matches.push(match[1]);
    }

    const validMatches = [];

    await Promise.all(
      matches.map(async (match) => {
        const query = db.collection('users').where('alias', '==', match);
        const querySnapshot = await query.get();

        if (querySnapshot.docs.length > 0) {
          validMatches.push({
            match,
            user_id: querySnapshot.docs[0].id
          });
        }
      })
    );

    // Update loading notification to success
    toast.update(loadingNotificationId, {
      render: "Reply created successfully",
      type: toast.TYPE.SUCCESS,
      autoClose: 3000, // Adjust the time as needed
    });
    
    // alle notifications naar db,  notificatie naar alle @s
    await Promise.all(
      validMatches.map(async (match) => {
        const newNoti = {
          from_user_id: currentUser.user_id,
          timestamp: firebase.firestore.Timestamp.now(),
          to_user_id: match.user_id,
          post_id: post_id,
          replychain: [post_id, ...replyChain],
          type: "mention_reply",
          is_new: true
        }

        await db.collection('notifications').add(newNoti)

        const socket = io("https://twatter-notifications.sandersita.repl.co");
        socket.emit(`noti`, newNoti)
      })
    )

    

    getReplies(post_id)
  }

  function onchangePostMessage(msg, cursorIndex) {
    const atIndex = msg.lastIndexOf('@', cursorIndex - 1);
  
    if (atIndex !== -1) {
      // Check if the "@" is immediately followed by valid characters
      const aliasStartIndex = atIndex + 1;
      const aliasEndIndex = cursorIndex;
      const alias = msg.substring(aliasStartIndex, aliasEndIndex);
  
      // Ensure that alias is not empty and only contains valid characters
      const isValidAlias = alias.length === 0 || /^[a-zA-Z0-9_]+$/.test(alias);
  
      if (isValidAlias) {
        // Show the dropdown with user suggestions
        setShowDropdown(true);
  
        // Update the filteredUsers based on the input after "@" symbol
        const queryAfterAt = alias.toLowerCase();
        const filteredUsers = users.filter(user =>
          user.alias && user.alias.toLowerCase().includes(queryAfterAt)
        );
  
        // Update the state with filtered users
        setFilteredUsers(filteredUsers);
      } else {
        // Hide the dropdown if "@" is not followed by valid characters
        setShowDropdown(false);
      }
    } else {
      // Hide the dropdown if "@" is not present
      setShowDropdown(false);
    }
  
    // Update the state with the new post message
    setReply(msg);
  }

  const setInputCursor = (index) => {
    const inputElement = document.getElementById('postMessage'); // Replace with your input element's actual ID
    inputElement.focus(); // Ensure the input element has focus
    inputElement.setSelectionRange(index, index); // Set cursor position
  };
  

  const handleUserSelection = (selectedAlias) => {
    const atIndex = reply.lastIndexOf('@');
    const cursorIndex = atIndex + 1; // Cursor index after the "@"
    
    const newPostMessage =
    reply.substring(0, cursorIndex) + selectedAlias + ' ' + reply.substring(cursorIndex);
    
    // Update the state with the new post message and hide the dropdown
    setReply(newPostMessage);
    setShowDropdown(false);
    
    // Move the cursor to after the inserted alias
    const newCursorIndex = cursorIndex + selectedAlias.length + 1; // +1 for the space
    setInputCursor(newCursorIndex); // You need a function to update the cursor position
  };

  const getUsers = async () => {
    const usersRef = db.collection('users');
    const usersSnapshot = await usersRef.get();
    const users = usersSnapshot.docs.map(doc => doc.data());
    setUsers(users)
    setFilteredUsers(users)
  }

  function back() {
    let url = `/post/${post_id}`;
  
    if (replyChain.length > 1) {
      // Pop the last reply ID from the array
      replyChain.pop();
  
      if (replyChain.length > 0) {
        const replyIds = replyChain.join('/'); // Join the remaining IDs with slashes
        url += `/reply/${replyIds}`;
      }
    } else if (replyChain.length === 0) {
      url = '/';
    }
  
    navigate(url);
  }

  return (
    <SkeletonTheme baseColor="#1e2a45" highlightColor="#2d4069">
      <div className="text-white border-l border-r border-gray-500">
        <div className='sticky top-0 z-50 w-full flex gap-6 border-b border-gray-600 text-white bg-gray-900 p-3'>
          <button onClick={() => back()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 my-auto">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>
          </button>

          <p className="my-auto text-xl font-bold">Post</p>
        </div>

          <div className="w-full p-2">
            {Object.keys(userData).length !== 0 ? (
              <Link className="cursor-pointer" to={`/profile/${userData.alias}`}>
                <div className="w-full flex gap-5">
                    <img className="w-12 h-12 rounded-md" src={userData.pfp_url}/>
                    <div className="flex-col">
                        <p className="font-bold cursor-pointer">{userData.username}</p>
                        <p className="text-gray-500">@{userData.alias}</p>
                    </div>
                </div>
             </Link>
            ) : (
              <div className="w-full flex gap-5">
                <Skeleton width={'3rem'} height={'3rem'} />
                <div className="flex-col">
                  <Skeleton width={'8rem'} height={'1rem'} />
                  <Skeleton width={'6rem'} height={'1rem'} />
                </div>
              </div>
            )}
            
              {Object.keys(userData).length !== 0  ? (
                <p className="mt-3 mb-3" dangerouslySetInnerHTML={{ __html: postText }}></p>
              ) : (
                <Skeleton className="mt-4" width={'14rem'} height={'1rem'} />
              )}
              
              
              {post.imgUrls && post.imgUrls.length > 0 && (
                  <div className='mt-2'>
                      {post.imgUrls[0].split('?')[0].endsWith(".mp4") ? (
                          <video controls>
                              <source src={post.imgUrls[0]} type='video/mp4' />
                              Your browser does not support the video tag.
                          </video>
                      ) : (
                        <Carousel className='rounded' dynamicWidth={true} dynamicHeight={true} emulateTouch={true} swipeable={true} showThumbs={false} showStatus={false} showIndicators={post.imgUrls && post.imgUrls.length > 1}>
                          {post.imgUrls.map(url => {
                            return <img key={url} src={url} className="max-h-full object-cover" />
                          })}
                        </Carousel>
                      )}
                  </div>
              )}
              {post.timestamp ? (
                <p className="mt-3 mb-3 text-gray-500">{formatTimestamp(post.timestamp)}</p>
              ) : (
                <Skeleton className="mt-2" width={'11rem'} height={'1rem'} />
              )}
              

              <div className="w-full p-2 flex gap-2 border-t border-b 2 border-gray-500">
                  <p className="font-bold">{postsLiked} </p>
                  <svg onClick={() => likePost(post_id)} xmlns="http://www.w3.org/2000/svg" fill={liked ? "red" : "none"} viewBox="0 0 24 24" strokeWidth={1.5} 
                    stroke={liked ? "red" : "gray"} className="w-6 h-6 cursor-pointer shadow-2xl">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                  </svg>
              </div>

              <div className="w-full p-1 border-gray-600 text-white">
                <form
                  className="space-y-4 md:space-y-6"
                  action="#"
                  onSubmit={replyToDatabase}
                >
                  <input
                    type="file"
                    id="file-input"
                    multiple
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                  {/* <label for="file-input" className="btn" onClick={openFileExplorer}>Select files</label> */}

                  <div className="flex gap-2 m-0">
                    {currentUser &&
                    <>
                      {currentUser.pfp_url ? (
                        <img
                          className="h-12 w-12 rounded-md"
                          src={
                            currentUser.pfp_url != ""
                              ? currentUser.pfp_url
                              : "https://firebasestorage.googleapis.com/v0/b/sql-api-e6e5f.appspot.com/o/images%2Fdefault_profile_picture.jpg?alt=media&token=1b9de581-b204-437f-b12b-918f4aaf20d2"
                          }
                      ></img>
                      ) : (
                        <Skeleton width={'3rem'} height={'3rem'} />
                      )}
                      </>
                    }
                    
                    

                    <div className="w-full flex flex-col gap-4 relative">
                      <input
                        type="text"
                        value={reply} 
                        name="postMessage"
                        id="postMessage"
                        onChange={(e) => onchangePostMessage(e.target.value, e.target.selectionStart)}
                        onBlur={() => setTimeout(() => setShowDropdown(false), 100)}
                        className="h-12 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Reply to this post">
                      </input>

                      {dropdown &&
                        <ul className="absolute z-40 p-2 bg-gray-900 w-full border rounded top-0">
                            {filteredUsers.map(user => (
                                <a className='w-full' onClick={() => {handleUserSelection(user.alias); setShowDropdown(false)}}>
                                    <div className="flex p-2 items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                                    <img className='w-10 h-10 rounded' src={user.pfp_url}></img>
                                    <div className='flex flex-col'>
                                        <span className="text-[15px] ml-4 text-gray-200 font-bold text-left">{user.username}</span>
                                        <span className="text-[15px] ml-4 text-gray-500 text-left">@{user.alias}</span>
                                    </div>
                                    </div>
                                </a>
                            ))}
                        </ul>
                    }

                      {files.length > 0 && 
                        <div className="selected-files w-full flex flex-wrap">
                          <Carousel className='rounded' dynamicWidth={true} dynamicHeight={true} emulateTouch={true} swipeable={true} showThumbs={false} showStatus={false} showIndicators={post.imgUrls && post.imgUrls.length > 1}>
                          {Array.from(files).map((file) => {
                            return <img
                              key={file}
                              src={URL.createObjectURL(file)}
                              alt="selected"
                              className="w-1/2"
                            />
                          })}
                          </Carousel>
                        </div>
                      }
                      

                      <div className="grid grid-cols-2">
                        <div className="flex gap-4">
                          <svg
                            onClick={openFileExplorer}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="cursor-pointer my-auto w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                            />
                          </svg>
                          <svg
                            onClick={toggleEmojiPicker}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="cursor-pointer my-auto w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                            />
                          </svg>
                        </div>
                        <button
                          type="submit"
                          className="justify-self-end w-24 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        >
                          Reply
                        </button>
                      </div>
                      {showEmojiPicker && (
                        <div
                          className="absolute left-0 right-0 z-10 mx-auto"
                          style={{ maxWidth: "400px" }}
                        >
                          <EmojiPicker onEmojiClick={handleEmojiClick} theme="dark" />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
          </div>
        
          <div>
            {replies1.length > 0 ? 
                replies1.map(reply => (
                    <Reply
                      key={reply.id}
                      reply={reply}
                      post_id={post_id}
                      reply_id={reply.id}
                      replyChainList={replyChainList}
                      setReplyChainList={setReplyChainList}
                  />
                )) : <p className="text-center text-gray-500 border-b border-t border-gray-500">Be the first to reply!</p>
            }
          </div>
      </div>
    </SkeletonTheme>
  );
};

function formatTimestamp(timestamp){
    const date = new Date(timestamp);

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const time = date.toLocaleString('en-US', options);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const formattedDate = `${time} · ${day} ${month}. ${year}`;

    return formattedDate;
}

export default Post;

import { useState, useEffect } from "react";
import firebase, { storage, db } from "./Firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { io } from 'socket.io-client';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from "react-responsive-carousel";  
import { useAuth } from "./FirebaseAuthContext";
import { showPopup } from "../sweetalert";

function FeedPost(props) {
  const [liked, setLiked] = useState(false);
  const [postsLiked, setPostLikes] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    // Set liked state
      hasUserLiked();
    
  }, []);

  // get post from prop
  const post = props.post;
  

  // Check if user had liked this post
  const hasUserLiked = async () => {
    const postRef = db.collection("posts").doc(post.id).collection("likes");
    const currentUser = await getCurrentUserId();

    if (currentUser) {
      const querySnapshot = await postRef
        .where("userId", "==", currentUser)
        .get();
      setLiked(!querySnapshot.empty);
    }

    // Get the updated number of likes from the database
    const LikesSnapshot = await postRef.get();
    const LikesCount = LikesSnapshot.size;
    setPostLikes(post.likes.length);
  };

  function getUserFromPost(post_id){
    const postRef = db.collection('posts').doc(post_id)
    return postRef.get()
  }

  // Update liked status to database
  const likePost = async (post_id) => {
    if(!currentUser){
      showPopup(post.alias, 'like')
      return;
    }

    // Get a reference to the current user ID
    const currentUserId = await getCurrentUserId(); // Assuming you have a function that returns the current user ID

    try {
      // Get a reference to the post document
      const postRef = db.collection("posts").doc(post_id).collection("likes");
      if (!liked) {
        // ADD NOTIFICATION TO OTHER USER
        const notiRef = db.collection('notifications')
        const user = await getUserFromPost(post_id)
        const userIdFromPost = user.data().user_id
        if(currentUserId !== userIdFromPost){
          const newNoti = {
            from_user_id: currentUserId,
            timestamp: firebase.firestore.Timestamp.now(),
            to_user_id: userIdFromPost,
            post_id: post_id,
            type: "like",
            is_new: true
          }
  
          notiRef.add(newNoti)
  
          const socket = io("https://twatter-notifications.sandersita.repl.co");
          socket.emit(`noti`, newNoti)
        }

        // Add a new document to the "likes" subcollection with the current user ID and timestamp
        await postRef.add({
          userId: currentUserId,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
        setLiked(true);

        // add like to likedPosts collection
        const likedPostsRef = db.collection('likedPosts')
        await likedPostsRef.add({
          post_id,
          from_user_id: currentUserId,
          timestamp: firebase.firestore.Timestamp.now(),
          userIdFromPost
        })
      } else {

        const querySnapshot = await postRef
          .where("userId", "==", currentUserId)
          .get();
        querySnapshot.forEach(async (doc) => {
          await doc.ref.delete();
        });
        setLiked(false);

        // delete like to likedPosts collection
        const likedPostsRef = db.collection('likedPosts');

        // Query the collection to retrieve the document with the specified post_id
        const querySnapshotlikedPosts = await likedPostsRef.where('post_id', '==', post_id).where('from_user_id', '==', currentUserId).get();

        if (!querySnapshotlikedPosts.empty) {
          // Get the document reference and delete it
          querySnapshotlikedPosts.forEach(async (doc) => {
            await doc.ref.delete();
          });
          console.log('Document deleted successfully.');
        }
      }

      // Get the updated number of likes from the database
      const updatedLikesSnapshot = await postRef.get();
      const updatedLikesCount = updatedLikesSnapshot.size;

      // Update the state of the number of likes for the post
      setPostLikes(updatedLikesCount);
      setLiked(!liked);
    } catch (error) {
      console.error(`Error liking post ${post_id}: ${error}`);
    }
  };

  const getCurrentUserId = async () => {
    const user = firebase.auth().currentUser;
    return user ? user.uid : null;
  };

  const blueText = post.text.replace(/@([a-zA-Z0-9_]+)/g, '<span className="text-blue-500">@$1</span>');
  return (
    <div key={post.id} className="w-full p-5 border-b-2 border-gray-600">
    <div className="flex gap-5">
      <Link
        state={{ user_id: post.user_id }}
        to={`/profile/${post.alias}`}
      >
        {post.pfp_url !== undefined ? (
          <div className="w-12 h-12 rounded-md object-cover square-image">
          <img
            className="w-full h-full rounded-md object-cover"
            src={post.pfp_url}
            alt="Profile"
          />
        </div>
        ) : null}
            
          </Link>
          <div className="flex flex-col w-full">
            <p>
              <Link
                state={{ user_id: post.user_id }}
                to={`/profile/${post.alias}`}
              >
                <span className="text-white font-bold">{post.username}</span>{" "}
                <span className="text-gray-600">@{post.alias}</span>
              </Link>
              <span className="text-gray-600">
                {" "}
                · {getTimeSince(post.timestamp)}
              </span>
            </p>

            <Link to={`/post/${post.id}`}>
              <p className="text-white cursor-pointer" dangerouslySetInnerHTML={{ __html: blueText }}></p>
            </Link>

            
              {post.imgUrls && post.imgUrls.length > 0 && (
                <div className="mt-2">
                  {post.imgUrls.length === 1 ? (
                    // If there's only one URL, check if it's a video URL
                    post.imgUrls[0].split('?')[0].endsWith(".mp4") ? (
                      <video src={post.imgUrls[0]} controls className="w-full">
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      // Otherwise, it's an image URL
                      <Link to={`/post/${post.id}`}>
                        <img key={"img_" + post.id} src={post.imgUrls[0]} className="w-full sm:w-1/2" />
                      </Link>
                      
                    )
                  ) : (
                    // If there are multiple URLs, check each URL individually
                    <div className="w-full flex gap-5">
                      <Carousel className='rounded' dynamicHeight={true} emulateTouch={true} swipeable={true} showThumbs={false} showStatus={false} showIndicators={post.imgUrls.length > 1}>
                        {post.imgUrls.map((url) => {
                          if (!url.endsWith('.mp4')) {
                            // If the file is an image, render an <img> tag inside the carousel slide
                            return (
                              <Link to={`/post/${post.id}`}>
                              <div key={url}>
                                <img
                                  src={url}
                                  className="max-w-"
                                />
                              </div>
                              </Link>
                            );
                          } else if (url.endsWith('.mp4')) {
                            // If the file is a video, render a <video> tag inside the carousel slide
                            return (
                              <div key={url}>
                                <video controls className="">
                                  <source src={url} type="video" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            );
                          }

                          // If the file type is unknown or unsupported, you can render an error message or handle it accordingly
                          return (
                            <div key={url}>Unsupported file type</div>
                          );
                        })}
                        
                      </Carousel>
                    </div>
                  )}
                </div>
              )}
            
            
            <div className="w-full flex gap-5 mt-2 text-gray-400">
              <Link className="flex gap-4" to={`/post/${post.id}`}>
                <p>{post.replycount}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                  />
                </svg>
              </Link>

              <p>{postsLiked}</p>
              <svg
                onClick={() => likePost(post.id)}
                xmlns="http://www.w3.org/2000/svg"
                fill={liked ? "red" : "none"}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={liked ? "red" : "gray"}
                className="w-6 h-6 cursor-pointer shadow-2xl"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
  );
}

function getTimeSince(timestamp) {
  let inputDate = timestamp;
  let inputTimestamp = new Date(inputDate).getTime();
  let currentTimestamp = new Date().getTime();
  let difference = currentTimestamp - inputTimestamp;

  let seconds = Math.floor(difference / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let months = Math.floor(days / 30);

  if (seconds < 60) {
    return seconds + "s";
  } else if (minutes < 60) {
    return minutes + "m";
  } else if (hours < 24) {
    return hours + "h";
  } else if (days < 30) {
    return days + "d";
  } else {
    return months + "mo";
  }
}

export default FeedPost;

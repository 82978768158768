import React from 'react';
import Feed from './Feed';
import Profile from './Profile';
import { Routes, Route } from 'react-router-dom';
import Post from './Post';
import Recommendations from './Recommendations';
import Notifications from './Notifications';
import AbsoluteElements from './AbsoluteElements';

function Dashboard(){
  return (
    <div className="grid grid-cols-4">
      <div className="col-span-1 hidden md:block">
        {/* Left Div Content */}
      </div>
      <div className="col-span-4 md:col-span-2">
        {/* Middle Div Content */}
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/profile/:username" element={<Profile />} />
          <Route path="/post/:post_id" element={<Post />} />
          <Route path="/post/:post_id/*" element={<Post />} />
          <Route path="/notifications" element={<Notifications />} />
        </Routes>
        <div className='h-52'></div>
      </div>
      <div className="col-span-1 hidden md:block">
        {/* Right Div Content */}
        <Recommendations />
      </div>
      <AbsoluteElements />
    </div>
  );
}

export default Dashboard;

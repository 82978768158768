import RecentChats from "./RecentChats";
import FullChat from "./FullChat";
import firebase, { storage, db } from "./Firebase";
import { io } from 'socket.io-client';
import { act } from "react-dom/test-utils";
import React, { useState, useEffect, useRef } from "react";
// import { useAuth } from './FirebaseAuthContext'

const ChatLayout = () => {
  const [activeChat, setActiveChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [redDot, setRedDot] = useState(false);
  const [chatId, setChatId] = useState(null);
  const chatIdRef = useRef(null); // Use useRef to store chatId
  // const { currentUser } = useAuth();

  useEffect(() => {
    // if(!currentUser){
    //   return null;
    // }
    getChats();
  }, []);

  const updateChats = (newchat) => {
    setChats(newchat)
  }


  const getChats = async () => {
    try {
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          
          const socket = io("https://twatter-socket.sandersita.repl.co");
          socket.on(`chatnoti-${user.uid}`, async (text, sender_id, chatUserListWithoutCur, chat_id) => {
            const senderRef = await db.collection('users').doc(sender_id).get();
            const senderData = senderRef.data()
            let userList = [...chatUserListWithoutCur, sender_id]

            // if groupchat
            let groupchatdata = false;
            if(userList.length > 2){
              const chatRef = db.collection('chats').doc(chat_id)
              const chatRefSnapshot = await chatRef.get()
              groupchatdata = chatRefSnapshot.data()
            }

            // chatIdRef.current = chat_id;
            setRedDot(chatIdRef.current !== chat_id);
            setChats((prevChats) => {
              const chatExists = prevChats.some((chat) => chat.id === chat_id);
              if (!chatExists) {
                // If the chat_id does not exist, create a new object with the specified format
                const newChatObject = {
                  id: chat_id,
                  users: userList,
                  lastmessage: text,
                  lastmessageTimestamp: new Date(),
                  image: groupchatdata ? groupchatdata.pfp : senderData.pfp_url,
                  name: groupchatdata ? groupchatdata.groupchatname : senderData.username,
                  alias: !groupchatdata ? senderData.alias : '',
                  isUnread: true, // Set isUnread to true for the newly created chat
                };
            
                // Use setChats to add the new object to the chats array
                const updatedChats = [...prevChats, newChatObject];
            
                // Sort the updatedChats array based on lastmessageTimestamp in descending order
                updatedChats.sort((a, b) => b.lastmessageTimestamp - a.lastmessageTimestamp);
            
                return updatedChats;
              }
          
              // If the chat_id exists, update the existing chat object
              const updatedChats = prevChats.map((chat) => {
                if (chat.id === chat_id) {
                  return {
                    ...chat,
                    isUnread: chatIdRef.current !== chat_id,
                    lastmessage: text,
                    lastmessageTimestamp: new Date(),
                  };
                }
                return chat;
              });
          
              return updatedChats;
            });

            setChats(prevChats => {
              const chatIndex = prevChats.findIndex(chat => chat.id === chat_id);
              if (chatIndex !== -1) {
                const chatToMove = prevChats.splice(chatIndex, 1)[0];
                prevChats.unshift(chatToMove);
              }
              return [...prevChats]; // Return a new array to trigger state update
            });
          });

          const chatsRef = db.collection("chats");
          const query = chatsRef.where("users", "array-contains", user.uid);
          const querySnapshot = await query.get();
          const newChats = [];
          for (const doc of querySnapshot.docs) {
            const chat = doc.data();
            const chatMessagesRef = doc.ref.collection("messages");
            const chatMessagesSnapshot = await chatMessagesRef.orderBy("timestamp", "desc").limit(1).get();
            chat.id = doc.id;
            if(chatMessagesSnapshot.docs.length > 0){
              let lastmessage;
              const chatData = chatMessagesSnapshot.docs[0].data()
              if(chatData.imgUrls.length > 0){
                lastmessage = chatData.imgUrls[0].split('?')[0].endsWith('.mp4') ? "Sent a video" : "Sent an image"
              }else{
                lastmessage = chatMessagesSnapshot.docs[0].data().text
              }
              chat.lastmessage = lastmessage;
              const timestamp = chatMessagesSnapshot.docs[0].data().timestamp;
              const jsTimestamp = timestamp.toDate().getTime();
              chat.lastmessageTimestamp = jsTimestamp;
            }
            
            if(chat.users.length == 2){
              for (const userId of chat.users) {
                if (userId !== user.uid) {
  
                  const usersRef = db.collection('users');
                  const userRef = usersRef.doc(userId);
                  const user = await userRef.get()
                  const userData = user.data()
  
                  chat.image = userData.pfp_url;
                  chat.name = userData.username;
                  chat.alias = userData.alias;
                }
              }
            }

            // check if chat > userUnreadStatus -> isUnread == true, voeg dan toe aan chat obj
            const chatUnreadRef = doc.ref.collection("userUnreadStatus").doc(user.uid);
            const unreadReference = await chatUnreadRef.get()
            const unread = unreadReference.data()
            chat.isUnread = unread.isUnread

            newChats.push(chat);
          }
          // Sort the chats by the lastmessage timestamp in descending order (newest on top)
          newChats.sort((a, b) => {
            return b.lastmessageTimestamp - a.lastmessageTimestamp;
          });

          setChats(newChats);
        }
      });
    } catch (error) {
      console.error("Error getting chats:", error);
    }
  };

  return (
    <div className="flex">
      <RecentChats redDot={redDot} setRedDot={setRedDot} chats={chats} setActiveChat={setActiveChat} />
      {activeChat && (
        <FullChat chatIdRef={chatIdRef} chats={chats} updateChats={updateChats} activeChat={activeChat} setActiveChat={setActiveChat} />
      )}
    </div>
  );
};

export default ChatLayout;
// FirebaseAuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase, { storage, db, auth } from "./Firebase";
import { useNavigate } from 'react-router-dom';
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";

const FirebaseAuthContext = createContext();

export const useAuth = () => {
  return useContext(FirebaseAuthContext);
};

export const FirebaseAuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged( async (user) => {
      if(user){
        
        const userRef = await db.collection('users').doc(user.uid).get();
        const userData = userRef.data();
        user = {...user, ...userData}
      }
      
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signup = async (email, password, username, birth) => {

    return new Promise(async (resolve, reject) => {
        try{
            const userRecord = await auth.createUserWithEmailAndPassword(email, password);
            const uid = userRecord.user.uid;
            
            const currentDate = new Date();
            const joinedDate = currentDate.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
            const additionalData = {
              user_id: uid,
              email: email,
              username,
              alias: username,
              birth,
              bio: "",
              joined_date: joinedDate,
              pfp_url:
                "https://firebasestorage.googleapis.com/v0/b/sql-api-e6e5f.appspot.com/o/images%2Fdefault_profile_picture.jpg?alt=media&token=1b9de581-b204-437f-b12b-918f4aaf20d2",
              banner_url: "",
            };
        
            const followers = {
              user_id: uid,
              followers: [],
            };
        
            const following = {
              user_id: uid,
              following: [],
            };
        
            await db.collection("users").doc(uid).set(additionalData);
            await db.collection("followers").doc(uid).set(followers);
            await db.collection("following").doc(uid).set(following);
    
            
            login(email, password)
            resolve('done');
        } catch(e) {
            // Something went wrong
            reject(e);
        }
    })
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  };

  const logout = () => {
    return auth.signOut()
  };

  // connect to replit host, to send email

  return (
    <FirebaseAuthContext.Provider value={{currentUser, signup, login, logout, loading}}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export default function Home() {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 flex flex-col justify-center items-center min-h-screen">
      <p className="text-[60px] text-white">Twatter</p>

      <div className="text-[30px] text-white p-5 text-center">
        Connect, Share and Express yourself on the ultimate social media
        platform.
      </div>

      <div className="flex p-10 gap-2">
        <a
          href="/login"
          className="inline-flex items-center w-full px-5 py-3 mb-3 mr-1 text-base font-semibold text-white no-underline align-middle bg-blue-600 border border-transparent border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700"
        >
          Login
        </a>
        <a
          href="/register"
          className="inline-flex items-center w-full px-5 py-3 mb-3 mr-1 text-base font-semibold text-white no-underline align-middle bg-blue-600 border border-transparent border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700"
        >
          Register
          <svg
            className="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            ></path>
          </svg>
        </a>
      </div>

      <div className="flex justify-center gap-5 mt-30 flex flex-col sm:flex-row w-70">
        <div className="rounded-lg shadow-lg bg-white max-w-sm text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-20 h-20 text-red mx-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">
              Explore new interests
            </h5>
            <p className="text-gray-700 text-base mb-4">
              Our platform is home to a diverse community of people from all
              over the world, each with their own unique interests and passions.
              Whether you're into cooking, photography, or politics, you're sure
              to find something that piques your interest on our platform.
              Browse through our selection of trending topics and discover new
              interests, or use our search bar to find people and groups that
              share your passions.
            </p>
          </div>
        </div>

        <div className="rounded-lg shadow-lg bg-white max-w-sm text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-20 h-20 text-red mx-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
            />
          </svg>

          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">
              Connect with friends
            </h5>
            <p className="text-gray-700 text-base mb-4">
              Our platform makes it easy to stay connected with the people who
              matter most to you. Follow your friends and family, and keep up to
              date with their latest posts and updates. Direct message anyone on
              the platform to have a private conversation. You can also join
              groups to discuss and share your thoughts with like-minded people.
            </p>
          </div>
        </div>

        <div className="rounded-lg shadow-lg bg-white max-w-sm text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-20 h-20 text-red mx-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
            />
          </svg>

          <div className="p-6">
            <h5 className="text-gray-900 text-xl font-medium mb-2">
              Share your voice
            </h5>
            <p className="text-gray-700 text-base mb-4">
              Our platform is a place where you can express yourself freely and
              share your thoughts, ideas and daily musings with friends and
              followers alike. Use our powerful tools to create and share
              content, including text, images, videos and more. Whether you're
              looking to promote your business, share your latest project, or
              simply chat with friends, our platform has something for everyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

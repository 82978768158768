import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import EditProfile from './EditProfile';
import FeedPost from './FeedPost';
import firebase, { storage, db } from './Firebase';
import FullChat from './FullChat';
import { io } from 'socket.io-client';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import ReplyPost from './ReplyPost';
import { showPopup } from '../sweetalert';

const Profile = () => {

    const navigate = useNavigate();

    const { username } = useParams();

    const [posts, setPosts] = useState([]);
    const [profileData, setprofileData] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [isFollowing, setIsFollowing] = useState(false);

    const [followerCount, setFollowerCount] = useState(0);

    const [activeChat, setActiveChat] = useState(null);

    const [postState, setPostState] = useState('recent');

    const [likedPosts, setLikedPosts] = useState([]);

    const [chats, setChats] = useState([]);

    const [replyPosts, setReplyPosts] = useState([]);

    const [currentUserID, setCurrentUserID] = useState('')

    const updateChats = (newchat) => {
      setChats(newchat)
    }

    useEffect(() => {
      fetchPosts();
      firebase.auth().onAuthStateChanged(async function (user) {
        if(user){
          setCurrentUserID(user.uid);
          userData(user.uid);
          getLikedPosts(user.uid);
        }
      })
      
      
      getReplyPosts();
    }, []);

    // Update following data if 'isFollowing' state changes
    useEffect(() => {
      userData();
    }, [isFollowing]);

    // Get all posts from user
    const fetchPosts = async () => {
      // Get the user from the 'users' collection with the matching alias
      const userRef = db.collection("users").where("alias", "==", username);
      const userSnapshot = await userRef.get();
      if (userSnapshot.empty) {
        console.log("something went wrong!!")
        return;
      }

      // Get the user's posts
      const userDocs = userSnapshot.docs;
      const userDoc = userDocs[0];
      const user_id = userDoc.id;

      try {
        const snapshot = await db
          .collection("posts")
          .where("user_id", "==", user_id)
          .orderBy("timestamp", "desc")
          .get();

        const posts = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const postData = doc.data();
            postData.id = doc.id;

            const userData = await db.collection("users").doc(postData.user_id).get();
            postData.username = userData.data().username;
            postData.alias = userData.data().alias;
            postData.pfp_url = userData.data().pfp_url;

            const likesSnapshot = await db.collection("posts").doc(postData.id).collection("likes").get();
            postData.likes = likesSnapshot.docs.map((doc) => doc.data().userId);

            const replySnapshot = await db.collection("posts").doc(postData.id).collection("replies").get();
            postData.replycount = replySnapshot.size;

            return postData;
          })
        );

        setPosts(posts);
      } catch (error) {
        console.log("Error getting documents", error);
      }
    }

    const getLikedPosts = async (currentUserId) => {
      if(currentUserId){
        const userRef = db.collection("users").where("alias", "==", username);
        const userSnapshot = await userRef.get();
        if (userSnapshot.empty) {
          console.log("something went wrong!!")
          return;
        }

        // Get the user's posts
        const userDocs = userSnapshot.docs;
        const userDoc = userDocs[0];
        const user_id = userDoc.id;

        const likedPostsRef = await db.collection('likedPosts').where('from_user_id', '==', user_id).orderBy('timestamp', 'desc').get();

        const likedPosts = await Promise.all(
          likedPostsRef.docs.map(async (doc) => {
            const postData = doc.data();
            postData.id = postData.post_id;
    
            const userData = await db.collection("users").doc(postData.userIdFromPost).get();
            postData.username = userData.data().username;
            postData.alias = userData.data().alias;
            postData.pfp_url = userData.data().pfp_url;
    
            const likesSnapshot = await db.collection("posts").doc(postData.post_id).collection("likes").get();
            postData.likes = likesSnapshot.docs.map((doc) => doc.data().userId);
    
            const replySnapshot = await db.collection("posts").doc(postData.post_id).collection("replies").get();
            postData.replycount = replySnapshot.size;
    
            // Convert Firestore timestamp to desired string format
            const timestamp = new Date(postData.timestamp.seconds * 1000 + postData.timestamp.nanoseconds / 1e6);
            postData.timestamp = timestamp.toISOString();

            const postSnapshot = await db.collection("posts").doc(postData.post_id).get();
            postData.text = postSnapshot.data().text;
            postData.imgUrls = postSnapshot.data().imgUrls;

    
            return postData;
          })
        );
    
        setLikedPosts(likedPosts);
      };
    };

    const getReplyPosts = async () => {
      firebase.auth().onAuthStateChanged(async function (user) {
        const userRef = db.collection("users").where("alias", "==", username);
        const userSnapshot = await userRef.get();
        if (userSnapshot.empty) {
          console.log("something went wrong!!")
          return;
        }

        // Get the user's posts
        const userDocs = userSnapshot.docs;
        const userDoc = userDocs[0];
        const user_id = userDoc.id;

        const replyPostsRef = await db.collection('replyPosts').where('user_id', '==', user_id).orderBy('timestamp', 'desc').get();

        const replyPosts = await Promise.all(
          replyPostsRef.docs.map(async (doc) => {
            const postData = doc.data();
            const lastReplyId = postData.replyChain[postData.replyChain.length - 2];
            postData.id = lastReplyId;
    
            const userData = await db.collection("users").doc(postData.user_id_from_parent).get();
            postData.username = userData.data().username;
            postData.alias = userData.data().alias;
            postData.pfp_url = userData.data().pfp_url;

            let data = db.collection("posts").doc(postData.replyChain[0]);

            for (let i = 1; i < postData.replyChain.length - 1; i++) {
              data = data.collection("replies").doc(postData.replyChain[i]);
            }
    
            const likesSnapshot = await data.collection("likes").get();
            postData.likes = likesSnapshot.docs.map((doc) => doc.data().userId);
    
            const replySnapshot = await data.collection("replies").get();
            postData.replycount = replySnapshot.size;

            const postSnapshot = await data.get();
            postData.text = postSnapshot.data().text;
            postData.imgUrls = postSnapshot.data().imgUrls;

    
            return postData;
          })
        );
    
        setReplyPosts(replyPosts);
      });
    };
    

    // Get all user data from profile
    const userData = async (currentUser) => {
      const response = await fetch(`https://webserverToFirebase.sandersita.repl.co/profile/${username}`);
      const data = await response.json();
      followStatus(data, currentUser);
      setprofileData(data);
    }

    const handleEditProfile = () => {
      setShowModal(true);
    }

    const handleCloseModal = () => {
      setShowModal(false);
    };

    // Check if logged in user is following profile or not
    const followStatus = (profileData, currentUserId) => {
      if(currentUserId){
          if (profileData && profileData.followers && profileData.followers[0] && profileData.followers[0].followers && currentUserId) {
            for (let userid of profileData.followers[0].followers) {
              if (userid === currentUserId) {
                // IS Following
                setIsFollowing(true);
                return;
              }
            }
            // Not following
            setIsFollowing(false);
          }
      };
    }

    // Follow button, follow or unfollow based on 'isFollowing' state
    const followUser = async () => {
      const user = firebase.auth().currentUser;
      
      if(!user){
        showPopup(profileData.user.alias, 'follow')
        return;
      }
      const user_id = user.uid;
      
      let userIdFromProfile;

      const usersRef = db.collection('users');
      const querySnapshot = await usersRef.where('email', '==', profileData.user.email).get();

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.data().user_id;
        userIdFromProfile = userId;
      } else {
        userIdFromProfile = null;
      }

      if(isFollowing){
        // REMOVE CURRENT USER ID OUT OF THE FOLLOWERS LIST OF THE OTHER USER
        if(userIdFromProfile){
          const followers = db.collection('followers');
          const querySnapshot = await followers.where('user_id', '==', userIdFromProfile).get();
          if (!querySnapshot.empty) {
            const docRef = querySnapshot.docs[0].ref;
            await docRef.update({
              followers: firebase.firestore.FieldValue.arrayRemove(user_id)
            });
            setIsFollowing(false)
          }
        }

        // GET THE OTHER USER ID OUT OF THE FOLLOWING LIST OF CURRENT USER
        if(userIdFromProfile){
          const followers = db.collection('following');
          const querySnapshot = await followers.where('user_id', '==', user_id).get();
          if (!querySnapshot.empty) {
            const docRef = querySnapshot.docs[0].ref;
            await docRef.update({
              following: firebase.firestore.FieldValue.arrayRemove(userIdFromProfile)
            });
          }
        }
      }else{
        // ADD NOTIFICATION TO OTHER USER
        if(user_id !== userIdFromProfile){
          const notiRef = db.collection('notifications')
          const newNoti = {
            from_user_id: user_id,
            timestamp: firebase.firestore.Timestamp.now(),
            to_user_id: userIdFromProfile,
            type: "follow",
            is_new: true
          }
          notiRef.add(newNoti)
  
          const socket = io("https://twatter-notifications.sandersita.repl.co");
          socket.emit(`noti`, newNoti)
        }

        // ADD CURRENT USER ID TO THE FOLLOWERS LIST OF THE OTHER USER
        if (userIdFromProfile) {
          const followers = db.collection('followers');
          const querySnapshot = await followers.where('user_id', '==', userIdFromProfile).get();
        
          if (!querySnapshot.empty) {
            const docRef = querySnapshot.docs[0].ref;
            await docRef.update({
              followers: firebase.firestore.FieldValue.arrayUnion(user_id)
            });
          }

          setIsFollowing(true)
        }
        
        // ADD THE OTHER USER ID TO THE FOLLOWING LIST OF CURRENT USER
        if (userIdFromProfile) {
          const following = db.collection('following');
          const querySnapshot = await following.where('user_id', '==', user_id).get();
        
          if (!querySnapshot.empty) {
            const docRef = querySnapshot.docs[0].ref;
            await docRef.update({
              following: firebase.firestore.FieldValue.arrayUnion(userIdFromProfile)
            });
          }
        }
      }
    }

    // start a new chat
    const startChat = async () => {
      const user = firebase.auth().currentUser;
      if(!user){
        showPopup(profileData.user.alias, 'chat')
        return;
      }
      const user_id = user.uid;
      const chatsRef = db.collection('chats');
      const query = chatsRef.where('users', 'array-contains', user_id);
      const querySnapshot = await query.get();
      const newUserList = [user_id, profileData.userId];
      let exists = false;
      let chatId;
    
      for (const doc of querySnapshot.docs) {
        const chat = doc.data();
    
        if (chatAlreadyExists(chat.users, newUserList)) {
          console.log("chat already exists");
          exists = true;
          chatId = doc.id;
        }
      }
    
      if (!exists) {
        const chatRef = db.collection('chats');
        const newChat = await chatRef.add({
          users: [
            user_id,
            profileData.userId
          ]
        });
        chatId = newChat.id;

        // add unReadStatus collection with all both users
        const chatDocRef = chatRef.doc(chatId)

        const batch = db.batch();
        newUserList.forEach((userId) => {
          const unreadStatusRef = chatDocRef.collection('userUnreadStatus').doc(userId);
          if (userId !== user_id) {
            batch.set(unreadStatusRef, { isUnread: true });
          }else{
            batch.set(unreadStatusRef, { isUnread: false });
          }
        });

        batch.commit()
      }
    
      const activeChat = {
        id: chatId,
        name: username,
        users: newUserList
      };
    
      setActiveChat(activeChat);
    };
    

    // compare 2 strings, one of the 2 user ids, and the other is from all chats the user is in
    const chatAlreadyExists = (array1, array2) => {
      if(array1.length !== array2.length){
        return false;
      }

      array1 = array1.sort();
      array2 = array2.sort();

      for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
          return false;
        }
      }

      return true;
    }

  return (
    <SkeletonTheme baseColor="#1e2a45" highlightColor="#2d4069">
      <div className='border-r border-l 2 border-gray-600'> 
        <div style={{zIndex: '25'}} className='sticky top-0 z-30 w-full flex gap-6 border-b border-black text-white bg-gray-900'>

          {/* Back Button */}
          <button onClick={() => navigate(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 my-auto ml-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>
          </button>

          {/* Header */}
          <div className='flex-col'>
            <p className='text-xl font-bold'>{username}</p>
            {posts &&
              <p className='text-gray-600'>{posts.length} Posts</p>
            }         
          </div>
        </div>

        {/* Profile Banner Image */}
        <div className='h-60 bg-blue-700'>
          {profileData && profileData.user && profileData.user.banner_url && 
            <img className='w-full h-60' src={profileData.user.banner_url} />
          }
        </div>

        <div className='w-full h-20 flex px-10'>
          {/* Profile Picture */}
          {profileData && profileData.user ? (
            <img className='w-40 h-40 -translate-y-1/2 rounded-md border-4 border-gray-900 z-20' src={profileData.user.pfp_url}></img>
          ) : (
            <Skeleton className='-translate-y-1/2' width={'10rem'} height={'10rem'} />
          )}
          
          <div className='ml-auto my-auto flex gap-2'>
            {profileData && profileData.userId &&
              <>
                {currentUserID && profileData.userId == currentUserID ? (
                  <button onClick={handleEditProfile} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Edit Profile</button>
                ) : (
                  <>
                    {/* Chat Button */}
                    <div onClick={startChat} className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-500 text-white cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                      </svg>
                    </div>

                    {/* Follow Button */}
                    <button onClick={followUser} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 font-bold'>
                      {isFollowing ? "Unfollow" : "Follow"}
                    </button>
                  </>
                )}
              </>
            }
          </div>
        </div>

        {profileData  &&
          <div className='w-full flex-col mt-5 pl-10 text-white'>
            <div className=''>
              <p className='text-xl font-bold'>{profileData.user == undefined ? <Skeleton width={'60px'} /> : profileData.user.username}</p>
              <p className='text-gray-600'>{profileData.user == undefined ? <Skeleton width={'60px'} /> : "@" + profileData.user.alias}</p>
            </div>

            {profileData.user !== undefined && profileData.user.bio != "" &&
              <div className='py-2'>
                <span>{profileData.user.bio}</span>
              </div>
            }

            <div className='flex flex-col md:flex-row gap-3 mt-3 text-gray-600'>
                {profileData.user !== undefined ? (
                  <>
                    <div>
                      <span className='text-gray-600'><i className="bi bi-balloon"></i> Birth date {profileData.user.birth}</span>
                    </div>
                    <div>
                      <span className='text-gray-600'><i className='bi bi-calendar4-week'></i> joined {profileData.user.joined_date}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <span><Skeleton width={'300px'}/></span>
                  </>
                )}
            </div>

            <div className='flex gap-3 mt-3 text-gray-600'>
              {profileData.following && profileData.followers ? (
                <p>
                  <span className='text-white'>
                    {profileData.following[0] && profileData.following[0].following ? profileData.following[0].following.length : 0}
                  </span>{' '}Following{' '}
                  
                  <span className='text-white'>
                    {profileData.followers[0] && profileData.followers[0].followers ? profileData.followers[0].followers.length : 0}
                  </span>{' '}Followers{' '}
                </p>
              ) : (
                <p><Skeleton width={'250px'} /></p>
              )}
              
            </div>
          </div>
        }

        <div className="grid grid-cols-3 text-center border-b 2 border-black text-white mt-5">
            <button  onClick={() => setPostState("recent")} className={(postState == "recent" ? "border-b-2 border-blue-700 p-4" : "") + " col-span-1 font-bold pt-5 pb-5 hover:bg-gray-800"}>
              <span>Posts</span>
            </button>
            <button onClick={() => setPostState("replies")} className={(postState == "replies" ? "border-b-2 border-blue-700 p-4" : "") + " col-span-1 font-bold pt-5 pb-5 hover:bg-gray-800"}>Replies</button>
            <button onClick={() => setPostState("likes")} className={(postState == "likes" ? "border-b-2 border-blue-700 p-4" : "") + " col-span-1 font-bold pt-5 pb-5 hover:bg-gray-800"}>Likes</button>
        </div>

          <div className=''>
            {postState == 'recent' &&
            <>
              {posts.map(post => (
                <FeedPost key={post.id} post={post} />
              ))}
            </>
            }

            {postState == 'likes' &&
            <>
              {likedPosts.map(post => (
                <FeedPost key={post.id + 'like'} post={post} />
              ))}
            </>
            }

            {postState == 'replies' &&
            <>
              {replyPosts.map(post => (
                <ReplyPost key={post.id + 'post'} post={post} />
              ))}
            </>
            }
            
          </div>

          {showModal && profileData && (
            <EditProfile
              profileData={profileData}
              showModal={showModal} 
              setShowModal={setShowModal}
              handleCloseModal={handleCloseModal}
              userData={userData}
            />
        )}

      {activeChat && (
        <FullChat chatIdRef={null} chats={chats} updateChats={updateChats} activeChat={activeChat} setActiveChat={setActiveChat} />
      )}
        
      </div>
    </SkeletonTheme>
  )
}

export default Profile;
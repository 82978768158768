import React, { useState, useRef, useEffect } from "react";
import firebase, { storage, db } from "./Firebase";
import { Link } from "react-router-dom";
import { useAuth } from './FirebaseAuthContext';


const Recommendations = () => {
    const [top5users, setTop5users] = useState([]);
    const [joinedRecently, setJoinedRecently] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [dropdown, setShowDropdown] = useState('');
    const { currentUser } = useAuth();

    const getUsers = async () => {
        const usersRef = db.collection('users');
        const usersSnapshot = await usersRef.get();
        const users = usersSnapshot.docs.map(doc => doc.data());
        setUsers(users)
        setFilteredUsers(users)
    }

    const handleInputChange = event => {
        const newQuery = event.target.value;
        setSearchQuery(newQuery);
    
        // Update the user list based on the search query
        const filteredUsers = users.filter(user => {
            const usernameMatches = user.username && user.username.toLowerCase().includes(newQuery.toLowerCase());
            const aliasMatches = user.alias && user.alias.toLowerCase().includes(newQuery.toLowerCase());
            return usernameMatches || aliasMatches;
        });
          
        setFilteredUsers(filteredUsers);
    };

    const getMostFollowed = async () => {
        firebase.auth().onAuthStateChanged(async function (user) {
            const followersRef = db.collection('followers');
            let querySnapshot;
            try {
                if (user) {
                    querySnapshot = await followersRef
                        .where('user_id', '!=', user.uid)
                        .orderBy('user_id') // Change the orderBy to 'user_id'
                        .orderBy('followers', 'desc') // Add another orderBy for 'followers'
                        .limit(5)
                        .get();
                } else {
                    querySnapshot = await followersRef
                        .orderBy('followers', 'desc')
                        .limit(5)
                        .get();
                }
    
                let top5users = [];
    
                await Promise.all(querySnapshot.docs.map(async (userDoc) => {
                    const user = userDoc.data();
    
                    // Get the user data
                    const findUser = db.collection('users').doc(user.user_id);
                    const querySnapshot1 = await findUser.get();
    
                    // Add the user data to the top5
                    top5users.push(querySnapshot1.data());
                }));
    
                setTop5users(top5users);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    }
    
    
    

    const getRecentlyJoined = async () => {
        const usersRef = db.collection("users");
        const querySnapshot = await usersRef.orderBy("joined_date", "desc").limit(5).get();

        const recentUsersData = querySnapshot.docs.map((doc) => doc.data());
        setJoinedRecently(recentUsersData);
    }

    useEffect(() => {
        getUsers();
        getMostFollowed();
        getRecentlyJoined();
        
    }, []);
  
  return (
    <div className="text-white p-4 flex flex-col gap-2 sticky top-0">
        <div className="flex-col relative">
            <input className="w-full rounded p-2 text-black" type="text"  onFocus={() => setShowDropdown(true)} onBlur={() => setTimeout(() => setShowDropdown(false), 100)} value={searchQuery} onChange={handleInputChange} placeholder="Search on Twatter"></input>
            {dropdown &&
                <ul style={{height: '300px'}} className="absolute z-40 p-2 bg-gray-900 w-full border rounded overflow-y-scroll">
                    {filteredUsers.map(user => (
                        <a key={'hh'+user.alias} className='w-full' href={"/profile/" + user.alias}>
                            <div className="flex p-2 items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                            <img className='w-10 h-10 rounded' src={user.pfp_url}></img>
                            <div className='flex flex-col'>
                                <span className="text-[15px] ml-4 text-gray-200 font-bold text-left">{user.username}</span>
                                <span className="text-[15px] ml-4 text-gray-500 text-left">@{user.alias}</span>
                            </div>
                            </div>
                        </a>
                    ))}
                </ul>
            }
        </div>
       
        <div className="w-full rounded p-2 bg-gray-700">
            <h1 className="font-bold">Who to follow</h1>
            
            {top5users.map(user => (
                <a key={"top5_" + user.alias} className='w-full' href={"/profile/" + user.alias}>
                    <div className="flex p-2 items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                    <img className='w-10 h-10 rounded' src={user.pfp_url}></img>
                    <div className='flex flex-col'>
                        <span className="text-[15px] ml-4 text-gray-200 font-bold text-left">{user.username}</span>
                        <span className="text-[15px] ml-4 text-gray-500 text-left">@{user.alias}</span>
                    </div>
                    </div>
                </a>
            ))}
            
        </div>

        <div className="w-full rounded p-2 bg-gray-700">
            <h1 className="font-bold">Joined recently</h1>
            
            {joinedRecently.map(user => (
                <a key={'l'+user.alias} className='w-full' href={"/profile/" + user.alias}>
                    <div className="flex p-2 items-center rounded-md duration-300 cursor-pointer hover:bg-blue-600 text-white">
                    <img className='w-10 h-10 rounded' src={user.pfp_url}></img>
                    <div className='flex flex-col'>
                        <span className="text-[15px] ml-4 text-gray-200 font-bold text-left">{user.username}</span>
                        <span className="text-[15px] ml-4 text-gray-500 text-left">@{user.alias}</span>
                    </div>
                    </div>
                </a>
            ))}
            
        </div>
    </div>
  );
};

export default Recommendations;

import './App.css';
import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import Register from './components/Registeration';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';
import EditProfile from './components/EditProfile';
import PublicRoute from './components/PublicRoute';
import NotFound from './components/NotFound';

const App = () => {
  return (
      <Fragment>
          <Routes>
            <Route path="/*" element={<Dashboard/>} />
            <Route path="/login" element={<PublicRoute><Login/></PublicRoute>} />
            <Route path="/register" element={<PublicRoute><Register/></PublicRoute>} />
            <Route path="/home" element={<Home />} />
            <Route path="/editprofile" element={<PrivateRoute><EditProfile/></PrivateRoute>} />
            <Route path='*' element={<NotFound />}/>
          </Routes>
      </Fragment>
  );
}

export default App;

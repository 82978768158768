import './index.css';
import App from './App';
import Header from './components/Header';
import ChatLayout from './components/ChatLayout';
import LoginBar from './components/LoginBar';
import { BrowserRouter, useNavigate } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from './components/Firebase';
import {FirebaseAuthProvider} from './components/FirebaseAuthContext'
import AbsoluteElements from './components/AbsoluteElements';

const Root = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const socket = io("https://twatter-notifications.sandersita.repl.co");

    const unsubscribe = firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        socket.on(`noti-${user.uid}`, (msg) => {
          console.log('message recieved: ', msg)
          showNotification(msg)
        });
      }else{
        // user logged out
      }
    });

    // Clean up the event listeners and disconnect from the server when the component unmounts
    return () => {
      unsubscribe();
      socket.disconnect();
    };
  }, []);

  function showNotification(msg) {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClick: () => navigate("/notifications")
    });
  }

  return (
    <FirebaseAuthProvider>
      <div className="container">
        <ToastContainer />
        <div className="header z-50 sticky top-0">
          <Header />
        </div>
        <div className="content bg-gray-900">
          <App />
        </div>
      </div>
    </FirebaseAuthProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>
);


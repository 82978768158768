import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const typeText = {
    follow: {
        title: (alias) => {
            return `Follow ${alias} to see what they share on Twatter`
        },
        text: "Sign up so you never miss their posts",
        icon: "bi bi-person-plus"
    },
    chat: {
        title: (alias) => {
            return `Message ${alias} and see what they are up to`
        },
        text: "Sign up to start a chat",
        icon: "bi bi-chat"
    },
    post: {
        title: "Join the conversation!",
        text: "Sign up to post",
        icon: "bi bi-megaphone"
    },
    chat_solo: {
        title: "Start a conversation with your friends",
        text: "Sign up start a chat",
        icon: "bi bi-megaphone"
    },
    like: {
        title: (alias) => {
            return `Like what ${alias} has posted?`
        },
        text: "Sign up to interact with posts",
        icon: "bi bi-heart"
    },
    reply: {
        title: (alias) => {
            return `Reply to ${alias} post`
        },
        text: "Sign up to reply",
        icon: "bi bi-megaphone"
    }
}

export function showPopup(alias, type) {
  Swal.fire({
    html: `
        <div class="py-5">
            <div class="my-5">
                <i class="${typeText[type].icon} text-5xl text-blue-700"></i>
            </div>
        
            <div class="outline-none">
                <p class="text-white font-bold text-xl">${alias != '' ? typeText[type].title(alias) : typeText[type].title}</p>
                <p class="text-gray-600">${typeText[type].text}</p>
            </div>
            <div class="text-center mt-5">
                <button id="loginBtn"
                    type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 rounded-full py-3 w-full font-bold"
                    >
                    Login
                </button>
                
                <button id="registerBtn"
                    type="button"
                    class="mt-4 text-blue-600 bg-black hover:bg-gray-900 rounded-full py-3 w-full border border-gray-700 font-bold"
                    >
                    Register
                </button>
            </div>  
      </div>
    `,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    background: 'black'
  });

  const loginBtn = document.getElementById('loginBtn')
  const registerBtn = document.getElementById('registerBtn')
  loginBtn.onclick = () => {
    window.location.href = '/login'
  }

  registerBtn.onclick = () => {
    window.location.href = '/register'
  }
}
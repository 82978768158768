import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from './Firebase';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from './FirebaseAuthContext';

function Registeration() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const { signup } = useAuth();
  
  const [formIsValid, setFormIsValid] = useState(false);

  const navigate = useNavigate();

  // function that checks if all form fields are filled out
  function checkFormValidity() {
    return username !== '' && email !== '' && password !== '' && day !== '' && month !== '' && year !== '';
  }

  const handleSubmit = async event => {
    event.preventDefault();
    
    if(checkFormValidity()){
        // Show loading notification
        const loadingNotificationId = toast.info("Creating account...", { autoClose: 3000 });
        
        signup(email, password, username, formatDate(day, month, year))
        .then(() => {
            navigate('/')
            toast.update(loadingNotificationId, {
                render: "Account created successfully",
                type: toast.TYPE.SUCCESS,
                autoClose: 3000, // Adjust the time as needed
            });
        }).catch((e) => {
            console.log(e)
            setErrorMsg(e.message)
        })
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 h-screen">
        <ToastContainer />
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            Twatter    
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Create your account
                </h1>
                {errorMsg && 
                  <p className='text-red-500 border rounded border-red-500 p-1'>{errorMsg}</p>
                }
                <form className="space-y-4 md:space-y-6" action="#" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                        <input type="text" name="username" id="username" defaultValue={username} onChange={e => setUsername(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type your username" required></input>
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                        <input type="email" name="email" id="email" defaultValue={email} onChange={e => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required></input>
                    </div>
                    <div>
                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                        <input type="password" name="password" id="password" placeholder="••••••••" defaultValue={password} onChange={e => setPassword(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required></input>
                    </div>
                    <div className="rounded-md p-2">
                        <div className="flex flex-wrap -mx-2">
                            <div className="w-1/3 px-2">
                                <label className="block text-gray-700 dark:text-white font-medium mb-1" htmlFor="day">
                                    Day
                                </label>
                                <select id="day" className="w-full form-input py-2 px-3 rounded-md leading-5 text-gray-900 bg-white border border-gray-300 focus:ring-primary-600 focus:border-primary-600 focus:z-10 sm:text-sm" onChange={e => setDay(e.target.value)} required>
                                    <option selected disabled hidden defaultValue="">Day</option>
                                    {Array.from({length: 31}, (_, i) => i+1).map(day => <option defaultValue={day}>{day}</option>)}
                                </select>
                            </div>
                            <div className="w-1/3 px-2">
                                <label className="block text-gray-700 dark:text-white font-medium mb-1" htmlFor="month">
                                    Month
                                </label>
                                <select
                                    className="w-full form-select rounded-md py-2 px-3 text-gray-700 leading-5"
                                    id="month"
                                    onChange={e => setMonth(e.target.value)}
                                    required
                                >
                                    <option selected disabled hidden defaultValue="">Month</option>
                                    <option defaultValue="1">January</option>
                                    <option defaultValue="2">February</option>
                                    <option defaultValue="3">March</option>
                                    <option defaultValue="4">April</option>
                                    <option defaultValue="5">May</option>
                                    <option defaultValue="6">June</option>
                                    <option defaultValue="7">July</option>
                                    <option defaultValue="8">August</option>
                                    <option defaultValue="9">September</option>
                                    <option defaultValue="10">October</option>
                                    <option defaultValue="11">November</option>
                                    <option defaultValue="12">December</option>
                                </select>
                            </div>
                            <div className="w-1/3 px-2">
                                <label className="block text-gray-700 dark:text-white font-medium mb-1" htmlFor="year">
                                    Year
                                </label>
                                <select id="year" className="w-full form-input py-2 px-3 rounded-md leading-5 text-gray-900 bg-white border border-gray-300 focus:ring-primary-600 focus:border-primary-600 focus:z-10 sm:text-sm" onChange={e => setYear(e.target.value)} required>
                                    <option selected disabled hidden defaultValue="">Year</option>
                                    {Array.from({length: new Date().getFullYear() - (new Date().getFullYear() - 150)}, (_, i) => new Date().getFullYear() - i).map(year => <option defaultValue={year}>{year}</option>)}
                                </select>

                            </div>
                        </div>
                        </div>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full">Create an account</button>
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Already have an account? <a href="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a>
                    </p>
                </form>
            </div>
        </div>
    </div>
  </section>
    );
}

function formatDate(day, month, year){
    return `${day}-${month}-${year}`;
}



export default Registeration;

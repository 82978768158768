
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import firebase, { storage, db } from './Firebase';
import { Link } from "react-router-dom";

const Notifications = () => {
    const navigate = useNavigate();
    const [currentUserId, setCurrentUserId] = useState("");
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
      firebase.auth().onAuthStateChanged(async function(user) {
        if (user) {
          setCurrentUserId(user.uid);
        }
      });
    }, []);
    
    useEffect(() => {
      const markNotificationsAsSeen = async () => {
        try {
          // Query notifications collection based on the current user's ID
          const notificationsRef = db.collection('notifications').where('to_user_id', '==', currentUserId);
          const querySnapshot = await notificationsRef.get();
  
          // Update each document found to set isNew to false
          const batch = db.batch();
          querySnapshot.forEach((doc) => {
            const notificationRef = db.collection('notifications').doc(doc.id);
            batch.update(notificationRef, { is_new: false });
          });
  
          // Commit the batch update
          await batch.commit();
  
          console.log('Notifications marked as seen successfully.');
        } catch (error) {
          console.error('Error marking notifications as seen:', error);
        }
      };

      if (currentUserId !== "") {
        getNotifications();
        markNotificationsAsSeen()
      }
    }, [currentUserId]);
    
    async function getNotifications() {
      const notificationRef = db.collection('notifications');
      const query = notificationRef.where('to_user_id', '==', currentUserId).orderBy("timestamp", "desc").limit(20);
      const querySnapshot = await query.get();
    
      const notificationsList = [];
    
      // Create an array of promises for each async operation in the loop
      const promises = querySnapshot.docs.map(async (doc) => {
        const userRef = db.collection('users')
        const userSnapshot = await userRef.where('user_id', '==', doc.data().from_user_id).get()
        const userDoc = userSnapshot.docs[0];
    
        const notificationData = doc.data();
        notificationData.username = userDoc.data().username;
        notificationData.alias = userDoc.data().alias;
        notificationData.pfp = userDoc.data().pfp_url;

        if(userDoc.data().replychain !== undefined){
          notificationData.post_id = notificationData.post_id + '/reply/' + notificationData.replychain.map(id => id + '/').join('')
        }
    
        switch(doc.data().type){
          case 'like':
            notificationData.text = 'has liked your post'
            notificationData.icon = 'bi bi-heart-fill text-red-600'
            break;
          case 'follow':
            notificationData.text = 'has followed you'
            notificationData.icon = 'bi bi-person-fill text-sky-500'
            break;
          case 'reply':
            notificationData.text = 'has replied to your post'
            notificationData.icon = 'bi bi-reply-fill text-emerald-500'
            notificationData.reply_text = doc.data().reply_text
            break;
          case 'mention':
            notificationData.text = 'has mentioned you in their post'
            notificationData.icon = 'bi bi-at text-indigo-500'
            notificationData.reply_text = doc.data().reply_text
            break;
          case 'mention_reply':
            notificationData.text = 'has mentioned you in their reply'
            notificationData.icon = 'bi bi-at text-indigo-500'
            notificationData.reply_text = doc.data().reply_text
            break;
        }
    
        notificationsList.push(notificationData);
      });
    
      // Wait for all promises to resolve before setting the notifications state
      await Promise.all(promises);
    
      setNotifications(notificationsList);
    }
    
    

  return (
    <div className='border-r border-l border-b 2 border-gray-600 text-white'> 
      <div className='sticky top-0 z-50 w-full flex gap-6 border-b border-gray-600 text-white bg-gray-900 p-3'>

        <button onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 my-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
          </svg>
        </button>

        <div className='flex-col'>
          <p className='text-xl font-bold'>Notifications</p>
        </div>
      </div>

      {notifications !== null &&
        <>
          {notifications.map((noti) => (
            <Link to={noti.post_id ? `/post/${noti.post_id}` : `/profile/${noti.alias}`}>
              <div className='flex p-4 gap-4 border-b border-gray-600 relative'>
                {noti.is_new &&
                  <span className="absolute right-0 bg-green-100 text-green-800 mr-4 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">New</span>
                }
                
                <i style={{'font-size': '25px'}} className={noti.icon}></i>
                <div className='flex-col gap-2'>
                  <img className='h-10 w-10 rounded' src={noti.pfp}></img>
                  <p className='mt-2'> <Link to={`/profile/${noti.alias}`}><span className='font-bold'>{noti.username} </span></Link>{noti.text}</p>
                  {noti.reply_text != undefined && (
                    <p className='mt-2'>{noti.reply_text}</p>
                  )}
                </div>
              </div>
            </Link>
          ))}

          {notifications.length == 0 &&
            <p className='text-gray-500 text-center'>You got no notifications yet!</p>
          }
        </>
      }
      
    </div>
  )
}



export default Notifications;
import React, { useState, useRef } from "react";
import firebase, { storage, db } from "./Firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditProfile = (props) => {
  const { profileData, showModal, setShowModal, handleCloseModal, userData } = props;
  const [banner, setBanner] = useState(
    profileData ? profileData.user.banner_url : ""
  );
  const [pfp, setPfp] = useState(profileData ? profileData.user.pfp_url : "");
  const [bannerUrl, setBannerUrl] = useState(
    profileData ? profileData.user.banner_url : ""
  );
  const [pfpUrl, setPfpUrl] = useState(
    profileData ? profileData.user.pfp_url : ""
  );

  const [username, setUsername] = useState(
    profileData ? profileData.user.username : ""
  );
  const [bio, setBio] = useState(profileData ? profileData.user.bio : "");
  const [website, setWebsite] = useState(
    profileData && profileData.user.website ? profileData.user.website : ""
  );

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleClickPfp = () => {
    fileInputRef2.current.click();
  };

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    setBanner(URL.createObjectURL(selectedFile));
  };

  const handleChangePfp = (event) => {
    const selectedFile = event.target.files[0];
    setPfp(URL.createObjectURL(selectedFile));
  };

  const saveProfile = async () => {
    let list = [];
    if (banner !== bannerUrl) {
      if(banner !== ''){
        list.push(await uploadImage(banner));
      }else{
        list.push('')
      }
      
    } else {
      list.push(bannerUrl);
    }

    if (pfp !== pfpUrl) {
      list.push(await uploadImage(pfp));
    } else {
      list.push(pfpUrl);
    }

    firebase.auth().onAuthStateChanged(async function (user) {
      try {
        var user_id = user.uid;

        let data = {
          banner_url: list[0],
          pfp_url: list[1],
          username,
          bio,
          website,
          user_id: user_id,
        };

        const res = await fetch(
          "https://webserverToFirebase.sandersita.repl.co/updateuser",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        showNotification("Profile saved!")
        userData()

        setBannerUrl(list[0]);
        setPfpUrl(list[1]);
      } catch (err) {
        showNotification("Something went wrong!")
      }
    });

    setShowModal(false)
  };

  const fetchBlob = async (url) => {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "default",
    });
    const clone = response.clone();
    const blob = await clone.blob();
    return blob;
  };

  const uploadImage = async (image) => {
    try {
      let image_url = "";
      const blob = await fetchBlob(image);
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`images/${new Date().getTime()}`);
      const uploadTask = await fileRef.put(blob);

      if (uploadTask.state === "success") {
        image_url = await uploadTask.ref.getDownloadURL();
        return image_url;
      }
    } catch (err) {
      console.log(err);
    }
  };

  function showNotification(msg) {
    toast(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark"
    });
  }

  return (
    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="flex h-screen justify-center items-center fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <ToastContainer />
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex h-10 gap-3">
            <button
              onClick={handleCloseModal}
              type="button"
              className="ml-5"
              data-modal-hide="authentication-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <h3 className="my-auto font-medium text-gray-900 dark:text-white">
              Edit profile
            </h3>

            <button
              onClick={saveProfile}
              className="border-2 bg-white rounded-2xl ml-auto mr-5 w-20 h-8 my-auto"
            >
              Save
            </button>
          </div>

          <div className="w-full p-5 text-white">
            <div className="flex gap-5 justify-center items-center h-40 relative bg-sky-700">
              <div className="absolute top-50% left-50% transform -translate-y-50% -translate-x-50% flex gap-3">
                <div
                  onClick={handleClick}
                  className="bg-black p-2 rounded-full bg-opacity-50 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleChange}
                  />
                </div>

                <div onClick={() => setBanner('')} className="bg-black p-2 rounded-full bg-opacity-50 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              {banner !== "" &&
                <img
                  className="w-full object-cover h-48"
                  src={banner == "" ? "" : banner}
                />
              }
              
            </div>

            <div className="h-32 w-32 relative -translate-y-1/3 ml-10">
              <img
                className="object-fit-cover h-32 w-32 rounded"
                src={pfp == "" ? "" : pfp}
              />
              <div
                onClick={handleClickPfp}
                className="cursor-pointer h-10 w-10 bg-black p-2 rounded-full bg-opacity-50 absolute top-0 left-0 bottom-0 right-0 m-auto"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 absolute top-0 left-0 bottom-0 right-0 m-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <input
                  type="file"
                  ref={fileInputRef2}
                  style={{ display: "none" }}
                  onChange={handleChangePfp}
                />
              </div>
            </div>

            <form className="space-y-6" action="#">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Name
                </label>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  defaultValue={profileData ? profileData.user.username : ""}
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                />
              </div>

              <div>
                <label
                  for="bio"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Bio
                </label>
                <textarea
                  onChange={(e) => setBio(e.target.value)}
                  defaultValue={profileData ? profileData.user.bio : ""}
                  id="bio"
                  rows="4"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Write your thoughts here..."
                ></textarea>
              </div>

              <div>
                <label
                  htmlFor="website"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Website
                </label>
                <input
                  onChange={(e) => setWebsite(e.target.value)}
                  defaultValue={profileData ? profileData.user.website : ""}
                  type="text"
                  name="website"
                  id="website"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;

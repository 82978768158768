// UserItem.js
import React from 'react';
import { useState, useEffect } from "react";

function UserItem({ user, addUser, isSelectedProp, setIsSelected }) {
  return (
    <div onClick={() => {addUser(user); setIsSelected(!isSelectedProp)}} style={{cursor:'pointer'}} className='w-full flex gap-3 p-4 hover:bg-gray-600'>
      <img className='rounded w-10 h-10 my-auto' src={user.pfp_url}></img>
      <div className='flex-col'>
        <p className='font-bold text-white'>{user.username}</p>
        <p className='text-gray-400'>@{user.alias}</p>
      </div>
      {isSelectedProp && 
        <i className="bi bi-check my-auto ml-auto text-3xl text-blue-500"></i>
      }
    </div>
  );
}


export default UserItem;
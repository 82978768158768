import React, { useState, useEffect, useRef } from 'react';
import firebase, { storage, db } from './Firebase';
import { io } from 'socket.io-client';
import EmojiPicker from "emoji-picker-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from "react-responsive-carousel";

const FullChat = ({ chatIdRef, chats, updateChats, activeChat, setActiveChat }) => {
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [files, setFiles] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const messageContainerRef = useRef();

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage(message + emojiObject.emoji); // append the selected emoji to the current message
  };

  const handleFileSelect1 = (event) => {
    setFiles(event.target.files);
  };

  const openFileExplorer1 = (event) => {
    event.preventDefault();
    document.getElementById("file-input1").click();
  };

  useEffect(() => {
    // Scroll to the bottom of the message container
    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  }, [messageList]);

  const getAllChats = () => {
    firebase.auth().onAuthStateChanged(async function(user) {
      if (user) {
        if(chatIdRef !== null){
          chatIdRef.current = activeChat.id
        }
        
        const chatsRef = db.collection('chats').doc(activeChat.id.trim());
        const newChats = [];

        // Get the chat messages
        const messages = chatsRef.collection('messages').orderBy("timestamp", 'asc');
        const messagesSnapshot = await messages.get();

        // get users meta data (username and pfp url)
        const docSnapshot = await chatsRef.get();

        let userMetaData = []

        if (docSnapshot.exists) {
          const chatData = docSnapshot.data();
          const userPromises = chatData.users.map(async userid => {
            const userRef = db.collection('users').where('user_id', '==', userid);
            const userQuerySnapshot = await userRef.get();
        
            if (!userQuerySnapshot.empty) {
              const userDataSnapshot = userQuerySnapshot.docs[0]; // Get the first document
              const userData = userDataSnapshot.data();
        
              return {
                user_id: userid,
                username: userData.username,
                pfp_url: userData.pfp_url
              };
            }
        
            return null;
          });
        
          // Wait for all user metadata promises to resolve
          userMetaData = await Promise.all(userPromises);
        
          // Filter out any potential null values (users not found)
          userMetaData = userMetaData.filter(user => user !== null);
        }

        
        for (const doc of messagesSnapshot.docs) {

          const message = doc.data();
          const date = message.timestamp.toDate();
          const formattedDate = date.toLocaleDateString("en-GB");

          const findUserData = userMetaData.find(user => user.user_id == message.sender_id)

          const newMessage = {
            content: message.text,
            time: message.timestamp,
            date: formattedDate,
            isMine: user.uid == message.sender_id,
            imgUrls: message.imgUrls,
            username: user.uid !== message.sender_id ? findUserData.username : '',
            pfp_url: user.uid !== message.sender_id ? findUserData.pfp_url : ''
          }

          newChats.push(newMessage);
        }
        setMessageList(newChats);


        // change the isUnread to false of the current user in the current chat
        const unreadRef = db.collection('chats').doc(activeChat.id).collection('userUnreadStatus').doc(user.uid)
        const querySnapshot = await unreadRef.get()
        if (!querySnapshot.empty) {
          const docRef = querySnapshot.ref;
          await docRef.update({
            isUnread: false
          });
        }
      }
    });

    // Connect to the server
    const socket = io("https://twatter-socket.sandersita.repl.co");

    firebase.auth().onAuthStateChanged(async function(user) {
      // Listen for incoming messages
      socket.on(`newmessage-${activeChat.id}`, async (text, sender_id, chatUserListWithoutCur, imgUrls, pfp_url, username) => {
        if(user.uid === sender_id){
          return;
        }
        
        const curUserUnreadRef = db.collection('chats').doc(activeChat.id).collection('userUnreadStatus').doc(user.uid);
        const docSnapshot = await curUserUnreadRef.get();

        if (docSnapshot.exists && docSnapshot.data().isUnread) {
          await curUserUnreadRef.update({
            isUnread: false
          });
        }

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString("en-GB");

        const newMessage = {
          content: text,
          isMine: false,
          time: new Date(),
          date: formattedDate,
          imgUrls,
          pfp_url,
          username
        }

        // es6 add new message
        setMessageList(prevMessages => [...prevMessages, newMessage]);
        
      });
    });

    return () => {
      // Disconnect from the server when the component unmounts
      socket.disconnect();
    };
  }

  useEffect(() => {
    getAllChats()
  }, []);

  const handleSend = async () => {
    firebase.auth().onAuthStateChanged(async function(user) {
      if (user && (message.trim() !== '' || files.length > 0)) {
        setFiles([])
        setMessage("");

        // Send the message to the chat

        let imgUrls = [];

        // If there are images, upload them to Cloud Storage and get the URLs
        if (files.length > 0) {
          const uploadPromises = [];
          for (const image of files) {
            const storageRef = storage.ref(`images/${image.name}`);
            const uploadTask = storageRef.put(image);
            uploadPromises.push(
              new Promise((resolve, reject) => {
                uploadTask.on(
                  "state_changed",
                  null,
                  (error) => {
                    console.error(error);
                    reject(error);
                  },
                  async () => {
                    const url = await uploadTask.snapshot.ref.getDownloadURL();
                    resolve(url);
                  }
                );
              })
            );
          }
          imgUrls = await Promise.all(uploadPromises);
        }

        const dbNewMessage = {
          sender_id: user.uid,
          text: message,
          timestamp: new Date(),
          imgUrls          
        }

        const chatRef = db.collection('chats').doc(activeChat.id).collection('messages')
        await chatRef.add(dbNewMessage)

        let reciever_id;
        let chatUserListWithoutCur = []
        activeChat.users.forEach(async userid => {
          if(userid != user.uid){
            chatUserListWithoutCur.push(userid)

            // change the other users hasUnreadMessages to true
            const unreadRef = db.collection('chats').doc(activeChat.id).collection('userUnreadStatus').doc(userid);
            const querySnapshot = await unreadRef.get();

            if (querySnapshot.exists) {
              await unreadRef.update({
                isUnread: true
              });
            }

          }
        });

        // update chat UI
        updateChats((prevChats) => {
          const updatedChats = prevChats.map((chat) => {
            if (chat.id === activeChat.id) {
              return {
                ...chat,
                lastmessage: message,
                lastmessageTimestamp: new Date()
              };
            }
            return chat;
          });
          return updatedChats;
        });



        // Send the message to the server
        const socket = io("https://twatter-socket.sandersita.repl.co");

        let userData = await getCurrentUser(user.uid)
        let pfp_url = userData.data().pfp_url
        let username = userData.data().username
        socket.emit("newmessage", message, user.uid, chatUserListWithoutCur, activeChat.id, imgUrls, pfp_url, username);

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString("en-GB");

        const newMessage = {
          content: message,
          isMine: true,
          time: new Date(),
          date: formattedDate,
          imgUrls,
          username: '',
          pfp_url: ''
        }

        // es6 add new message
        setMessageList(prevMessages => [...prevMessages, newMessage]);

        updateChats(prevChats => {
          const chatIndex = prevChats.findIndex(chat => chat.id === activeChat.id);
          if (chatIndex !== -1) {
            const chatToMove = prevChats.splice(chatIndex, 1)[0];
            prevChats.unshift(chatToMove);
          }
          return [...prevChats]; // Return a new array to trigger state update
        });
      }
    });
  };
  
  // function to ge the time. first check if the date comes from the firestore or the js date
  function formatTimestamp(timestamp) {
    let date = null;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (timestamp && timestamp.toDate instanceof Function) {
      date = timestamp.toDate();
    }
    if (!date) {
      return '';
    }
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  async function getCurrentUser(userid) {
    const userRef = db.collection('users').doc(userid)

    return userRef.get()
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };
  
  return (
    <>
    <div className="fixed bottom-0 right-0 md:right-4 w-full md:w-96 max-w-xl bg-white border border-gray-200 rounded-t-lg shadow-md z-50">
      <div className="flex items-center justify-between p-3 border-b border-gray-200">
        <button
          className="p-1 rounded-full hover:bg-gray-100"
          onClick={() => {
            setActiveChat(null)
            chatIdRef.current = null
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <h2 className="font-medium text-gray-700">{activeChat.name == undefined ? activeChat.groupchatname : activeChat.name}</h2>
        <div className="w-6 h-6"></div>
      </div>
      <div className="h-80 overflow-y-scroll p-3" ref={messageContainerRef}>
        {messageList.map((message, index) => {
          const currentDate = message.date
          const previousDate = index > 0 ? messageList[index - 1].date : null;
          const isFirstMessageOfDay = currentDate !== previousDate;
          
          return (
            <>
              {isFirstMessageOfDay && (
                <div className="text-center font-medium text-gray-500 mb-2">
                  {currentDate == new Date().toLocaleDateString("en-GB") ? 'today' : currentDate}
                </div>
              )}
              <div key={index} className={`flex flex-col mb-3 rounded-full ${message.isMine ? 'items-end rounded-br-lg' : 'items-start rounded-bl-lg'}`}>
                <div className='flex gap-1'>
                  {message.pfp_url !== '' && message.pfp_url !== undefined &&
                    <img className='h-8 w-8 rounded-full' src={message.pfp_url}></img>
                  }

                  <div
                  className={`px-3 py-2 bg-blue-500 text-white max-w-xs ${message.isMine ? 'items-end rounded-br-lg' : 'items-start rounded-bl-lg'}`}
                  style={{ borderRadius: message.isMine ? '20px 20px 6px 20px' : '6px 20px 20px 20px' }}
                >
                  <h1 className='font-bold'>{message.username}</h1>
                  {message.content}
                  {message.imgUrls.length > 0 && (
                    <Carousel className='rounded' dynamicHeight={true} emulateTouch={true} swipeable={true} showThumbs={false} showStatus={false} showIndicators={files.length > 1}>
                      {Array.from(message.imgUrls).map((url) => {

                        if (!url.endsWith('.mp4')) {
                          // If the file is an image, render an <img> tag inside the carousel slide
                          return (
                            <div key={url}>
                              <img
                                src={url}
                                className="object-cover h-56 w-96"
                              />
                            </div>
                          );
                        } else if (url.endsWith('.mp4')) {
                          // If the file is a video, render a <video> tag inside the carousel slide
                          return (
                            <div key={url}>
                              <video controls className="">
                                <source src={url} type="video" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          );
                        }

                        // If the file type is unknown or unsupported, you can render an error message or handle it accordingly
                        return (
                          <div key={url}>Unsupported file type</div>
                        );
                      })}
                    </Carousel>
                  )}


                  
                </div>
                </div>
                


                <div className="text-sm text-gray-500 mt-1">{formatTimestamp(message.time)}</div>
              </div>
            </>
          );
        })}
      </div>
      <div className="border-t border-gray-200 px-3 py-2">
      {files.length > 0 && (
        <Carousel dynamicHeight={true} emulateTouch={true} swipeable={true} showThumbs={false} showStatus={false} showIndicators={files.length > 1}>
          {Array.from(files).map((file) => {
            const fileType = file.type.split('/')[0]; // Get the first part of the MIME type (e.g., 'image', 'video')

            if (fileType === 'image') {
              // If the file is an image, render an <img> tag inside the carousel slide
              return (
                <div key={file.name}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt="selected"
                    className="object-cover h-56 w-96"
                  />
                </div>
              );
            } else if (fileType === 'video') {
              // If the file is a video, render a <video> tag inside the carousel slide
              return (
                <div key={file.name}>
                  <video controls className="">
                    <source src={URL.createObjectURL(file)} type={file.type} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              );
            }

            // If the file type is unknown or unsupported, you can render an error message or handle it accordingly
            return (
              <div key={file.name}>Unsupported file type: {file.name}</div>
            );
          })}
        </Carousel>
      )}
        {/* <img src='https://i.kym-cdn.com/entries/icons/facebook/000/041/943/1aa1blank.jpg'></img> */}

        <input
          type="file"
          id="file-input1"
          multiple
          onChange={handleFileSelect1}
          style={{ display: "none" }}
        />
        <div className="flex items-center">
          <svg
            onClick={openFileExplorer1}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="cursor-pointer my-auto w-12 h-12" 
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
            
          <svg
            onClick={toggleEmojiPicker}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="cursor-pointer my-auto w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
            />
          </svg>
          
          
          <input
            className="w-full border border-gray-200 p-2 rounded-lg mr-2"
            type="text"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            onClick={handleSend}
          >
            Send
          </button>
        </div>
      </div>
      
    </div>
    {showEmojiPicker && (
      <div
        className="absolute right-10 bottom-16 z-50"
        style={{ maxWidth: "400px" }}
      >
        <EmojiPicker onEmojiClick={handleEmojiClick} theme="dark" />
      </div>
    )}
    </>
  );
  
};

export default FullChat;

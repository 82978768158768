import ChatLayout from "./ChatLayout"
import LoginBar from "./LoginBar"
import { useAuth } from './FirebaseAuthContext';

const AbsoluteElements = () => {
    const { currentUser, loading } = useAuth();

    return (
        <div>
            {!loading && 
            <>
            {!currentUser ? (
                <LoginBar/>
            ) : (
                <ChatLayout/>
            )}
            </>
            }
            
            
        </div>
    )
}

export default AbsoluteElements;